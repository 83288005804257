import { CompanyValue } from "../../types/CompanyValues"
import api from "./config/axiosConfig"

const COMPANY_VALUES_ENDPOINT = 'CompanyValues'

export const CompanyValuesAPI = {
  get: async function (id: number, cancel: boolean | false) {
    const response = await api.request({
      url: `/${COMPANY_VALUES_ENDPOINT}/get/${id}`,
      method: "GET"
    })

    return response.data
  },
  getAll: async function (cancel: boolean | false) {
    const response = await api.request({
      url: `/${COMPANY_VALUES_ENDPOINT}/getall`,
      method: "GET"
    })

    return response.data
  },
  create: async function (companyValue : CompanyValue, cancel: boolean | false) {
    await api.request({
      url: `/${COMPANY_VALUES_ENDPOINT}/create`,
      method: "POST",
      data: companyValue
    })
  },
  update: async function (companyValue : CompanyValue, cancel: boolean | false) {
    await api.request({
      url: `/${COMPANY_VALUES_ENDPOINT}/update`,
      method: "PUT",
      data: companyValue
    })
  },
  delete: async function (id: number, cancel: boolean | false) {
    await api.request({
      url: `/${COMPANY_VALUES_ENDPOINT}/delete/${id}`,
      method: "DELETE"
    })
  },
  import: async function (file: FormData, cancel: boolean | false) {
    await api.request({
      url: `/${COMPANY_VALUES_ENDPOINT}/import`,
      method: "POST",
      data: file
      //signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    })
  }
}