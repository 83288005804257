import { format } from "date-fns";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { AppliedJobOffer as AppliedJob } from "../../types/AppliedJobOffer";

interface AppliedTableProps {
  appliedJobs: AppliedJob[];
  isLoading: boolean;
  onToggleBookmark: (appliedJobId: number) => void;
}

const AppliedTable: React.FC<AppliedTableProps> = (props) => {
  return (
    <Grid container spacing={3} sx={{ mt: 4 }} alignItems="center">
      {props.isLoading ? (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Loading...
          </Typography>
        </Grid>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" color="textSecondary">
                    Company
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textSecondary">
                    Job title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textSecondary">
                    Date applied
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textSecondary">
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textSecondary">
                    Interview date
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {props.appliedJobs.map((appliedJob) => (
                <TableRow key={appliedJob.id}>
                  <TableCell>
                    <Typography variant="body1">
                      {appliedJob.company.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {appliedJob.job.jobTitle}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{appliedJob.status}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {appliedJob.interviewDateTime
                        ? format(
                            appliedJob.interviewDateTime,
                            "yyyy-MM-dd HH:mm:ss"
                          )
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => props.onToggleBookmark(appliedJob.job.id)}
                    >
                      {appliedJob.bookmarked ? (
                        <BookmarkIcon style={{ color: "#1a1a99" }} />
                      ) : (
                        <BookmarkBorderIcon style={{ color: "#1a1a99" }} />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default AppliedTable;
