import * as React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { Card, CardContent } from '@mui/material';

import MainImage from '../public/HomePage/MainImage.svg'; // The main image
import OrangeBlob from '../public/HomePage/OrangeBlob.svg';
import BlueBlob from '../public/HomePage/BlueBlob.svg';
import checklist_icon from '../public/HomePage/QuestionIcon.svg'; // First icon
import puzzle_piece_icon from '../public/HomePage/MatchIcon.svg'; // Second icon
import handshake_icon from '../public/HomePage/ConnectIcon.svg'; // Third icon

import { useAuth } from '../services/hooks/useAuth';

export default function HomePage() {
    const { login } = useAuth();

    React.useEffect(() => {
        login()
    }, []);
    
    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Container sx={{ flex: 1, margin: 0 }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: -2,
                        width: { xs: '70%', md: '50%' },
                        height: { xs: '30%', md: '50%' },
                        display: { xs: 'none', md: 'block' },
                        maxWidth: '720px',
                        maxHeight: '600px',
                    }}
                >
                    <img src={OrangeBlob} alt="Orange Blob" style={{ width: '100%', height: 'auto' }} />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: { xs: '200px', md: '500px' },
                        left: 0,
                        zIndex: -2,
                        width: { xs: '200px', md: '200px' },
                        height: { xs: '30%', md: '50%' },
                        display: { xs: 'none', md: 'block' },
                        maxWidth: '500px',
                        maxHeight: '700px',
                    }}
                >
                    <img src={BlueBlob} alt="Blue Blob" style={{ width: '100%', height: 'auto' }} />
                </Box>
                <Grid container spacing={2} sx={{ mt: { xs: 6, sm: 10, md: 8 } }}>
                    <Grid item xs={12} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="h6"
                                    pt={5} pb={3}
                                    textAlign={{ xs: 'center', md: 'left' }}
                                    sx={{ color:'#DF6951' }}
                                >
                                    150+ COMPANIES IN OUR DATABASE TODAY!
                                </Typography>
                                <Typography
                                    variant="h1"
                                    sx={{ mt: 2, textAlign: { xs: 'center', md: 'left' } }}
                                >
                                    Looking for your first job or internship?
                                </Typography>
                                <Button
                                    href="/survey"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        borderRadius: 2,
                                        width: { xs: '100%', sm: '350px', md: '450px' },
                                        fontSize: { xs: '20px', md: '32px' },
                                        mt: 3,
                                    }}
                                    endIcon={<ArrowForwardIcon sx={{ width: { xs: '24px', md: '32px' }, height: { xs: '24px', md: '32px' } }} />}
                                >
                                    TAKE THE TEST
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                container
                                justifyContent={{ xs: 'center', md: 'flex-end' }}
                                sx={{ mt: { xs: 6, sm: 6, md: 0 } }} 
                            >
                                <Box
                                    component="img"
                                    src={MainImage}
                                    alt="Main"
                                    sx={{
                                        maxWidth: { xs: '60%', sm: '70%', md: '80%' },
                                        height: 'auto',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center', mt: { xs: 10, sm: 30, md: 30 } }}>
                        <Typography variant="h2">
                            Start Your Career In Three Simple Steps
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="space-around" alignItems="center" spacing={3} sx={{ pb: { xs: 10, sm: 30, md: 30 } }}>
                        <Grid item xs={12} sm={4}>
                            <Card elevation={0} sx={{ textAlign: 'center', p: 3 }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={checklist_icon} alt="Answer questions" style={{ width: '80px', height: '80px', marginBottom: '16px' }} />
                                    <Typography variant="h6" gutterBottom>Answer questions</Typography>
                                    <Typography variant="body2">
                                        We want to understand your preferences around industry, compensation, career development, company culture and more.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Card elevation={0} sx={{ textAlign: 'center', p: 3 }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={puzzle_piece_icon} alt="Find your match" style={{ width: '80px', height: '80px', marginBottom: '16px' }} />
                                    <Typography variant="h6" gutterBottom>Find your match</Typography>
                                    <Typography variant="body2">
                                        We use this information and AI to match you to companies that match your preferences.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Card elevation={0} sx={{ textAlign: 'center', p: 3 }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={handshake_icon} alt="We connect you to companies" style={{ width: '80px', height: '80px', marginBottom: '16px' }} />
                                    <Typography variant="h6" gutterBottom>We connect you to companies</Typography>
                                    <Typography variant="body2">
                                        Choose to connect to companies that you find interesting, and we connect you to the recruiter. Your new job is just a click away.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
