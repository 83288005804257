import { Jwt } from "../../types/Jwt";
import { AuthUser } from "../../types/User";

const TOKEN_KEY = "jwtToken";

export class AuthUtility {

  static getJwtToken(): string | null {
    var jwtToken : string | null = null
    const cookie = `; ${document.cookie}`;

    const parts = cookie.split(`; ${TOKEN_KEY}=`);
    if (parts.length === 2) {
        jwtToken = parts.pop()?.split(';').shift() ?? null;
    }

    return jwtToken;
  }

  static removeJwtToken(): void {
    const currentDate = new Date();
    document.cookie = `${TOKEN_KEY}=; expires=${currentDate.toUTCString()}; path=/;`;
  }

  static isAuthenticated(token: Jwt | string | null | undefined): boolean {
    if (!token || this.isTokenExpired(token))
      return false;

    return true;
  }

  static isTokenExpired(token: Jwt | string | null): boolean {
    if (!token) {
      return true;
    }
      

    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds since Unix epoch
    var jwtToken: Jwt | null = null

    if (typeof token === "string") {
      jwtToken = this.parseJwtToken(token)
      if (!jwtToken) {
        return true;
      }
    } else {
      jwtToken = token
    }
      
    if (jwtToken.exp && typeof jwtToken.exp === "number") {
      return jwtToken.exp < currentTime;
    }

    return true;
  }

  static parseJwtToken(token: string): Jwt | null {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    
    var parsedJson = JSON.parse(jsonPayload);

    const jwt : Jwt | null = {
      jti: parsedJson.jti,
      iss: parsedJson.iss,
      aud: parsedJson.aud,
      exp: parsedJson.exp,
      email: parsedJson.email,
      givenName: parsedJson.given_name,
      familyName: parsedJson.family_name,
      roles: parsedJson.roles?.split(",")
    }

    return jwt;
  }

  static getAuthUserFromJwtToken(token: Jwt | string | null | undefined): AuthUser | null {
    if (!token)
      return null;

    var jwtToken: Jwt | null = null
    if (typeof token === "string") {
      jwtToken = this.parseJwtToken(token)
      if (!jwtToken) {
        return null;
      }
    } else {
      jwtToken = token
    }
    
    const user: AuthUser = {
      email: jwtToken.email,
      roles: jwtToken.roles,
      givenName: jwtToken.givenName,
      surnName: jwtToken.familyName
    }

    return user;
  }
}