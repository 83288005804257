import { Route, Routes as Routing } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SurveyPage from './pages/SurveyPage';
import ResultPage from './pages/ResultPage';
import CompanyValuesPage from './pages/CompanyValuesPage';
import CompaniesPage from './pages/CompaniesPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; 
import TermsOfServicePage from './pages/TermsOfServicePage'; 
import LoginPage from './pages/LoginPage';
import CompanyDetailPage from './pages/CompanyDetailPage'
import StudentPortalPage from './pages/StudentPortalPage';
import PrivateRoute from './components/general/PrivateRoute';
import { UserRoles } from './enums/UserRole';
import UnauthorizedPage from './pages/UnauthorizedPage';

function Routes() {
    return (
        <Routing>
            {/* PUBLIC ROUTING*/}
            <Route index element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />

            {/* PROTECTED ROUTING*/}
            <Route 
                path="/companies" 
                element={
                    <PrivateRoute allowedRoles={ [UserRoles.Admin] }>
                        <CompaniesPage />
                    </PrivateRoute>
                } />
            <Route 
                path="/company-values" 
                element={
                    <PrivateRoute allowedRoles={ [UserRoles.Admin] }>
                        <CompanyValuesPage />
                    </PrivateRoute>
                } />
            <Route 
                path="/portal" 
                element={
                    <PrivateRoute allowedRoles={ [UserRoles.Student, UserRoles.Admin, UserRoles.Company] }>
                        <StudentPortalPage />
                    </PrivateRoute>
                } />
            <Route 
                path="/survey" 
                element={
                    <PrivateRoute allowedRoles={ [UserRoles.Student, UserRoles.Admin, UserRoles.Company] }>
                        <SurveyPage />
                    </PrivateRoute>
                } />
            <Route 
                path="/survey-results" 
                element={
                    <PrivateRoute allowedRoles={ [UserRoles.Student, UserRoles.Admin, UserRoles.Company] }>
                        <ResultPage />
                    </PrivateRoute>
                } />
            <Route
                path="/company/:companyId"
                element={
                    <PrivateRoute allowedRoles={[UserRoles.Student, UserRoles.Admin, UserRoles.Company]}>
                        <CompanyDetailPage />
                    </PrivateRoute>
                } />
        </Routing>
    );
}

export default Routes;
