import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GraduateCompanyResultAPI } from "../services/api/graduate-company-result";
import { GraduateCompanyResult } from "../types/GraduateCompanyResult";
import {
  Button,
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import SalaryIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import CompanyTypeIcon from "@mui/icons-material/BusinessOutlined";
import CompanyBusinessTypeIcon from "@mui/icons-material/BusinessCenterOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FeedbackDialog from "../components/general/FeedbackDialog";
import companyCoverBg from "../assets/companycoverbg.png";
import DaytoDayImage from "../assets/DaytoDayDefault.png";
import CompanyCultureImage from "../assets/CompanyCultureDefault.png";
import { useRef } from "react"; 
import CompanyMatchFeedback from "../components/company_match/CompanyMatchFeedbackComponent";

export default function CompanyDetailPage() {
  const { companyId } = useParams<{ companyId: string }>();
  const [company, setCompany] = useState<GraduateCompanyResult | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [feedbackStatus, setFeedbackStatus] = useState<{
    [key: number]: { interested: boolean; notForMe: boolean };
  }>({});
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [dialogJobOpen, setDialogJobOpen] = useState(false);
  const [longDescription, setLongDescription] = useState("");

  useEffect(() => {
    fetchGraduateCompanyResults();
  }, [companyId]);

  const fetchGraduateCompanyResults = async () => {
    try {
      const response = await GraduateCompanyResultAPI.getTopSalary();
      fetchCompanyDetails(Number(companyId), response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const fetchCompanyDetails = async (
    companyId: number,
    companies: GraduateCompanyResult[] | null
  ) => {
    try {
      const companyData = companies?.find(
        (item: GraduateCompanyResult) => item.companyId === companyId
      );
      if (companyData) {
        setCompany(companyData);
        setFeedbackStatus({
          [companyData.companyId]: {
            interested: companyData.isInterested === true,
            notForMe: companyData.isInterested === false,
          },
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching company details:", error);
      setIsLoading(false);
    }
  };

  const handleGivenFeedback = useCallback(
    (companyId: number, answer: string, feedback: string) => {
      setFeedbackStatus((prev) => ({
        ...prev,
        [companyId]: {
          interested: answer === "Yes",
          notForMe: answer === "No",
        },
      }));

      const graduateCompanyResult = company;
      if (graduateCompanyResult) {
        graduateCompanyResult.feedback = feedback;
        graduateCompanyResult.isInterested = answer === "Yes";
        GraduateCompanyResultAPI.update(graduateCompanyResult).catch((e) => {
          console.log(e);
        });
      }
    },
    [company]
  );

  const getCompanyLogo = (companyName: string) => {
    try {
      return require(`../public/CompanyLogos/${companyName}.png`);
    } catch (error) {
      console.error("Error loading image for:", companyName, error);
      return null;
    }
  };

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleJobDialogOpen = (description?: string) => {
    setLongDescription(description ?? "");
    setDialogJobOpen(true);
  };

  const handleJobDialogClose = () => {
    setDialogJobOpen(false);
    setLongDescription("");
    };

    const jobOffersRef = useRef<HTMLDivElement | null>(null);

    const scrollToJobOffers = () => {
        if (jobOffersRef.current) {
            jobOffersRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }; 

  return (
    <Container>
      <Box
        sx={{
          backgroundImage: `url(${companyCoverBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100vw",
          height: "250px",
          position: "absolute",
          top: "0px",
          left: 0,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: 2,
          color: "white",
        }}
      ></Box>

      {isLoading ? (
        <Typography variant="h5" align="center" sx={{ mt: 4 }}>
          Loading company details...
        </Typography>
      ) : company ? (
        <Grid sx={{ marginTop: "200px" }}>
          <Box sx={{ marginTop: "50px", pb: { xs: 6, md: 10 } }}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              sx={{ mb: 4 }}
            >
              Back
            </Button>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              {getCompanyLogo(company.company.name) ? (
                <img
                  src={getCompanyLogo(company.company.name)}
                  alt={`${company.company.name} logo`}
                  style={{ maxHeight: "60px", maxWidth: "200px" }}
                />
              ) : (
                <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                  {company.company.name}
                </Typography>
              )}
            </Box>
            <Grid
              container
              spacing={6}
              sx={{
                justifyContent: "space-between",
                flexDirection: { xs: "column-reverse", md: "row" },
                mt: 3,
              }}
            >
              <Grid item xs={12} md={7}>
                <Box>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Company mission
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 3 }}
                  >
                    {company.company.mission}
                  </Typography>

                  <Typography
                    variant="h4"
                    color="textPrimary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Business activities
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 3 }}
                  >
                    {company.company.businessActivities}
                  </Typography>

                 {company.graduateCompanyJobOffers?.jobOffers.length > 0 && (
                   <Button
                     variant="contained"
                     color="primary"
                     onClick={scrollToJobOffers}
                     sx={{ mt: 2 }}
                   >
                   See Jobs
                   </Button>
                   )}  
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper elevation={0}>
                  <Box sx={{ mt: 1, mb: 4 }}>
                    <Box display="flex" alignItems="center">
                      <Tooltip
                        title="Location"
                        placement="bottom"
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                      >
                        <LocationIcon color="secondary" sx={{ mr: 1 }} />
                      </Tooltip>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        sx={{ mr: 1 }}
                      >
                        Location:
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 0.5, ml: 4 }}>
                      <Typography variant="subtitle2">
                        {company.company.location}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ mt: 1, mb: 4 }}>
                    <Box display="flex" alignItems="center">
                      <Tooltip
                        title="Estimated salary per month"
                        placement="bottom"
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                      >
                        <SalaryIcon color="secondary" sx={{ mr: 1 }} />
                      </Tooltip>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        sx={{ mr: 1 }}
                      >
                        Salary:
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 0.5, ml: 4 }}>
                      <Typography variant="subtitle2">
                        {company.company.salary}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mt: 0.5, ml: 4 }}
                    >
                      *based on the average starting position at the company and
                      there is a margin of error
                    </Typography>
                  </Box>

                  <Box sx={{ mt: 1, mb: 4 }}>
                    <Box display="flex" alignItems="center">
                      <Tooltip
                        title="Company Size"
                        placement="bottom"
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                      >
                        <CompanyTypeIcon color="secondary" sx={{ mr: 1 }} />
                      </Tooltip>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        sx={{ mr: 1 }}
                      >
                        Company size:
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 0.5, ml: 4 }}>
                      <Typography variant="subtitle2">
                        {company.company.size}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ mt: 1, mb: 4 }}>
                    <Box display="flex" alignItems="center">
                      <Tooltip
                        title="Industry Type"
                        placement="bottom"
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                      >
                        <CompanyBusinessTypeIcon
                          color="secondary"
                          sx={{ mr: 1 }}
                        />
                      </Tooltip>
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        sx={{ mr: 1 }}
                      >
                        Company industry:
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 0.5, ml: 4 }}>
                      <Typography variant="subtitle2">
                        {company.company.industry}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>

            {/* Day to day responsibilities */}
            <Box
              sx={{ flexGrow: 1, pt: { xs: 6, md: 10 }, pb: { xs: 6, md: 10 } }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <img
                    src={DaytoDayImage}
                    alt="Office scene"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      maxWidth: "500px",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" gutterBottom>
                    Day to day responsibilities
                  </Typography>
                  <Typography variant="body2">
                    {company.company.dayToDayResponsibilities}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Company culture */}
            <Box
              sx={{ flexGrow: 1, pt: { xs: 6, md: 10 }, pb: { xs: 6, md: 10 } }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6} sx={{ padding: { md: 10 } }}>
                  <Typography variant="h4" gutterBottom>
                    Company culture
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2, mt: 2 }}>
                    {company.company.companyCulture}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    src={CompanyCultureImage}
                    alt="Office scene"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      maxWidth: "500px",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

           <Box
            sx={{
              backgroundColor: "#EEF4FB",
              width: "100%",
              padding: "50px",
              textAlign: 'center'
            }}    >
              <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
                 General Facts
              </Typography>
              <Typography variant="body2">
                 {company.company.generalFacts}
              </Typography>
          </Box>

          {/* JOB CARDS */}
            <Box ref={jobOffersRef}  sx={{ pt: { xs: 6, md: 10 }, pb: { xs: 6, md: 10 }, mb: 20 }}>
            {company.graduateCompanyJobOffers?.jobOffers.length > 0 && (
              <Typography variant="h4" sx={{ textAlign: "center", mb: 5 }}>
                Current job openings:
              </Typography>
            )}
            <Grid container spacing={3}>
              {company.graduateCompanyJobOffers?.jobOffers.map(
                (jobs, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Card variant="outlined">
                      <CardHeader
                        title={jobs.jobTitle}
                        titleTypographyProps={{
                          variant: "h6",
                          fontWeight: "bold",
                        }}
                        sx={{ backgroundColor: "#ECECEE" }}
                      />
                      <CardContent>
                        <Box display="flex" alignItems="center" mb={1}>
                          <Tooltip
                            title="Location"
                            placement="bottom"
                            arrow
                            enterTouchDelay={0}
                            leaveTouchDelay={3000}
                          >
                            <LocationIcon color="secondary" sx={{ mr: 1 }} />
                          </Tooltip>
                          <Typography variant="body2" color="textSecondary">
                            {jobs.location}
                          </Typography>
                        </Box>
                        {jobs.salary && (
                          <Box display="flex" alignItems="center" mb={1}>
                            <Tooltip
                              title="Salary"
                              placement="bottom"
                              arrow
                              enterTouchDelay={0}
                              leaveTouchDelay={3000}
                            >
                              <SalaryIcon color="secondary" sx={{ mr: 1 }} />
                            </Tooltip>
                            <Typography variant="body2" color="textSecondary">
                              {jobs.salary}
                            </Typography>
                          </Box>
                        )}
                        {/*<Box display="flex" alignItems="center" mb={1}>*/}
                        {/*    <Tooltip title="Job Type" placement="bottom" arrow>*/}
                        {/*        <WorkIcon color='secondary' sx={{ mr: 1 }} />*/}
                        {/*    </Tooltip>*/}
                        {/*    <Typography variant="body2" color="textSecondary">*/}
                        {/*        Type of Job*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        {/*<Box display="flex" alignItems="center" mb={1}>*/}
                        {/*    <Tooltip title="Experience" placement="bottom" arrow>*/}
                        {/*        <ExperienceIcon color='secondary' sx={{ mr: 1 }} />*/}
                        {/*    </Tooltip>*/}
                        {/*    <Typography variant="body2" color="textSecondary">*/}
                        {/*        Experience needed*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          mb={2}
                        >
                          {jobs.shortJobDescription}
                        </Typography>
                        <Button
                          variant="text"
                          size="small"
                          onClick={() =>
                            handleJobDialogOpen(jobs.jobDescription)
                          }
                        >
                          Read More
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          </Box>

          {/* Action */}
          <Box
            sx={{
              position: "fixed",
              bottom: 50,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#ECECEE",
              width: "100%",
              padding: "20px",
              paddingBottom: {
                xs: "50px",
                sm: "20px",
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{ textAlign: "center", marginBottom: "10px" }}
            >
              What do you think about {company.company.name}?
            </Typography>
                          <Box
                              sx={{
                                  display: "flex",
                                  flexDirection: "row", // Force horizontal layout  
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 1,
                                  width: "100%",
                                  padding: "0",
                                  zIndex: 1000,
                                  "& .MuiCardActions-root": {
                                      flexDirection: "row !important", // Force horizontal layout  
                                      justifyContent: "center !important",
                                      alignItems: "center !important",
                                      gap: "8px !important", // Add spacing between buttons  
                                  },
                                  "& button": {
                                      width: "auto !important", // Ensure buttons don't stretch  
                                  },
                              }}
                          >
                              {company && (
                                  <CompanyMatchFeedback companyResult={company} />
                              )}
                          </Box>  
          </Box>
        </Grid>
      ) : (
        <Typography variant="h6" align="center" sx={{ mt: 4 }}>
          Company details not found.
        </Typography>
      )}

      <Dialog open={dialogJobOpen} onClose={handleJobDialogClose}>
        <DialogTitle>Job Description</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="textPrimary"
            sx={{ whiteSpace: "pre-line" }}
          >
            {longDescription}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJobDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
