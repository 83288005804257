import api from "./config/axiosConfig"

const GRADUATE_SURVEY_QUESTION_ENDPOINT = 'GraduateSurveyQuestion'

export const GraduateSurveyQuestionsAPI = {
  getAll: async function (cancel: boolean | false) {
    const response = await api.request({
      url: `/${GRADUATE_SURVEY_QUESTION_ENDPOINT}`,
      method: "GET"
    })

    return response.data
  },
}