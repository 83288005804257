import { GraduateCompanyResult } from "../../types/GraduateCompanyResult";
import api from "./config/axiosConfig";
import { makeRequest } from "./config/requestHelper";

const GRADUATE_COMPANY_RESULT_ENDPOINT = "GraduateCompanyResults";

export const GraduateCompanyResultAPI = {
  getTopSalary: async function () {
    try {
      const data = await makeRequest(
        `${GRADUATE_COMPANY_RESULT_ENDPOINT}/get-top-salary`,
        {
          method: "GET",
          requiresAuth: true,
        }
      );
      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  },

  create: async function (request: GraduateCompanyResult) {
    await api.request({
      url: `/${GRADUATE_COMPANY_RESULT_ENDPOINT}/create`,
      method: "POST",
      data: request,
    });
  },

  update: async function (request: GraduateCompanyResult) {
    await api.request({
      url: `/${GRADUATE_COMPANY_RESULT_ENDPOINT}/update`,
      method: "PUT",
      data: request,
    });
  },
};
