import { useEffect, useState } from "react";
import SummaryCards from "./SummaryCards";
import ProfileStatusBanner from "./ProfileStatusBanner";
import { UserDashboardCategory } from "../../enums/UserDashboardCategory";
import BookmarkedTable from "./BookmarkedTable";
import AppliedTable from "./AppliedTable";
import NotificationsTable from "./NotificationsTable";
import { JobOfferStatus } from "../../enums/JobOfferStatus";
import { AppliedJobOffer } from "../../types/AppliedJobOffer";
import { AppliedJobStatus } from "../../enums/AppliedJobStatus";
import { UserNotification } from "../../types/UserNotification";

const initialAppliedJobs: AppliedJobOffer[] = [
  {
    id: 1,
    job: {
      id: 1,
      jobTitle: "Marketing",
      status: JobOfferStatus.Available,
    },
    company: {
      id: 1,
      name: "Jumbo",
    },
    status: AppliedJobStatus.Applied,
    bookmarked: false,
    dateApplied: new Date(2024, 10, 2),
  },
  {
    id: 2,
    job: {
      id: 2,
      jobTitle: "Developer",
      status: JobOfferStatus.Closed,
    },
    company: {
      id: 2,
      name: "ProDrive",
    },
    bookmarked: true,
  },
  {
    id: 3,
    job: {
      id: 3,
      jobTitle: "Developer",
      status: JobOfferStatus.Available,
    },
    company: {
      id: 1,
      name: "ASML",
    },
    status: AppliedJobStatus.Scheduled,
    bookmarked: true,
    dateApplied: new Date(2024, 10, 22),
    interviewDateTime: new Date(2024, 11, 2, 14, 30, 0),
  },
  {
    id: 4,
    job: {
      id: 4,
      jobTitle: "Dev/Ops",
      status: JobOfferStatus.Available,
    },
    company: {
      id: 4,
      name: "Philips",
    },
    status: AppliedJobStatus.Scheduled,
    bookmarked: false,
    dateApplied: new Date(2024, 10, 2),
    interviewDateTime: new Date(2024, 11, 2, 14, 30, 0),
  },
];

const initialNotifications: UserNotification[] = [
  {
    id: 1,
    createdDateTime: new Date(2024, 11, 2, 14, 30, 0),
    message: "Application was scheduled for interview.",
  },
  {
    id: 2,
    createdDateTime: new Date(2024, 10, 2, 8, 30, 0),
    message: "Application was declined.",
  },
  {
    id: 3,
    createdDateTime: new Date(2024, 8, 2, 14, 6, 0),
    message: "Application was declined.",
  },
  {
    id: 4,
    createdDateTime: new Date(2024, 11, 2, 14, 30, 0),
    message: "Application was scheduled for interview.",
  },
];

interface UserDashboardProps {
  onEditCvClick: () => void;
}

const UserDashboard: React.FC<UserDashboardProps> = (props) => {
  const [appliedJobs, setAppliedJobs] = useState<AppliedJobOffer[]>([]);
  const [userNotifications, setUserNotifications] = useState<
    UserNotification[]
  >([]);
  const [selectedTable, setSelectedTable] = useState<string>(
    UserDashboardCategory.Bookedmarked
  );
  const [isLoadingJobs, setIsLoadingJobs] = useState<boolean>(false);
  const [isLoadingNotifications, setIsLoadingNotifications] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoadingJobs(true);
    setIsLoadingNotifications(true);

    setAppliedJobs(initialAppliedJobs);
    setUserNotifications(initialNotifications);

    setIsLoadingNotifications(false);
    setIsLoadingJobs(false);
  }, []);

  const onToggleBookmark = (id: number) => {
    setAppliedJobs((prevAppliedJobs) =>
      prevAppliedJobs.map((appliedJob) =>
        appliedJob.id === id
          ? { ...appliedJob, bookmarked: !appliedJob.bookmarked }
          : appliedJob
      )
    );
  };

  const renderTable = () => {
    switch (selectedTable) {
      case UserDashboardCategory.Bookedmarked:
        return (
          <BookmarkedTable
            isLoading={isLoadingJobs}
            appliedJobs={appliedJobs.filter(
              (appliedJob) => appliedJob.bookmarked === true
            )}
            onToggleBookmark={onToggleBookmark}
          />
        );
      case UserDashboardCategory.Applied:
        return (
          <AppliedTable
            isLoading={isLoadingJobs}
            appliedJobs={appliedJobs.filter(
              (appliedJob) => appliedJob.dateApplied
            )}
            onToggleBookmark={onToggleBookmark}
          />
        );
      case UserDashboardCategory.Notifications:
        return (
          <NotificationsTable
            isLoading={isLoadingNotifications}
            notifications={userNotifications}
          />
        );
      default:
        return <div>Something went wrong...</div>;
    }
  };

  return (
    <div className="App">
      <SummaryCards
        bookmarked={
          appliedJobs.filter((appliedJob) => appliedJob.bookmarked === true)
            .length
        }
        applied={
          appliedJobs.filter((appliedJob) => appliedJob.dateApplied).length
        }
        notifications={userNotifications.length}
        onCardClick={(category) => setSelectedTable(category)}
      />
      <ProfileStatusBanner onEditCvClick={props.onEditCvClick} />
      {renderTable()}
    </div>
  );
};

export default UserDashboard;
