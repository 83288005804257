import { Box, Grid, Button } from '@mui/material';
import Copyright from './Copyright';

const footerLinks = [
    { label: 'Terms of Service', href: '/terms-of-service' }, // Ensure paths start with "/"
    { label: 'Privacy Policy', href: '/privacy-policy' },
];

function Footer() {
    return (
        <Box sx={{ width: '100%', bgcolor: 'lightgrey', py: 1, position: 'fixed', bottom: 0, left: 0 }}>
            <Grid
                container
                alignItems="center"
                justifyContent={{ xs: 'center', md: 'space-between' }} // Center on small screens, space-between on larger screens
                sx={{ px: 4 }}
            >
                <Grid item sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Copyright />
                </Grid>
                <Grid item sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    {footerLinks.map((link) => (
                        <Button key={link.href} href={link.href} sx={{ mx: 1 }}>
                            {link.label}
                        </Button>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer;
