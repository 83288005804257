import React, { useCallback, useState, useEffect } from "react";
import {
  GraduateSurveyQuestion,
  QUESTION_TYPE,
} from "../../types/GraduateSurveyQuestion";
import { Kpi } from "../../types/Kpi";
import { styled, useMediaQuery } from "@mui/system";
import {
  Slider,
  Box,
  Button,
  IconButton,
  TooltipProps,
  tooltipClasses,
  ListItemButton,
  ListItemIcon,
  ListItem,
  Checkbox,
  ListItemText,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import GroupedQuestionComponent from "./GroupedQuestionComponent";
import { GraduateSurveyGroupedQuestion } from "../../types/GraduateSurveyGroupedQuestion";
import { GraduateSurveyGroupedResult } from "../../types/GraduateSurveyGroupedResult";
import FeedbackDialog from "../general/FeedbackDialog";

interface QuestionProps {
  question: GraduateSurveyQuestion;
  kpis: Kpi[];
  onSelectAnswer: (selectedValue: string) => void;
  onSelectGroupedAnswer: (selectedValue: GraduateSurveyGroupedResult[]) => void;
  handleGivenFeedback: (feedback: string) => void;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: (index: number) => void;
  selectedOptionsCheckbox: string[];
  setSelectedCheckboxOptions: (setSelectedCheckboxOptions: string[]) => void;
  groupedQuestions: GraduateSurveyGroupedQuestion[];
}

const Question: React.FC<QuestionProps> = ({
  question,
  kpis,
  onSelectAnswer,
  onSelectGroupedAnswer,
  handleGivenFeedback,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  selectedOptionsCheckbox,
  setSelectedCheckboxOptions,
  groupedQuestions,
}) => {
  const handleOptionChange = (selectedValue: string) => {
    onSelectAnswer(selectedValue);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [customAnswer, setCustomAnswer] = useState<string>("");
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const isMediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );

  const handleCheckboxChange = useCallback(
    (option: string) => {
      const index = selectedOptionsCheckbox.indexOf(option);
      if (index === -1) {
        setSelectedCheckboxOptions([...selectedOptionsCheckbox, option]);
      } else {
        const updatedOptions = [...selectedOptionsCheckbox];
        updatedOptions.splice(index, 1);
        setSelectedCheckboxOptions(updatedOptions);
      }
    },
    [selectedOptionsCheckbox, setSelectedCheckboxOptions]
  );

  const handleCheckboxNext = () => {
    // Check if no options are selected
    const answerToSend =
      selectedOptionsCheckbox.length === 0
        ? "No Preference"
        : selectedOptionsCheckbox.toString();

    onSelectAnswer(answerToSend);
    setSelectedCheckboxOptions([]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleDropdownChange = (selectedValue: string) => {
    onSelectAnswer(selectedValue);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleInputChange = (selectedValue: string) => {
    setSelectedCheckboxOptions([selectedValue]);
  };

  const handleNumberChange = (selectedValue: string) => {
    if (parseInt(selectedValue)) {
      setSelectedCheckboxOptions([selectedValue]);
    }
  };
  const handlePointAllocationChanged = (
    selectedValue: GraduateSurveyGroupedResult[]
  ) => {
    onSelectGroupedAnswer(selectedValue);
  };

  const handleInputNext = () => {
    onSelectAnswer(selectedOptionsCheckbox[0]);
    setSelectedCheckboxOptions([]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleFeedbackSubmit = (feedback: string) => {
    handleGivenFeedback(feedback);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: 12,
      border: "1px solid #dadde9",
    },
  }));

    const options = question?.possibleAnswers || [];
    //console.log(options);
  const numOptions = options.length;
  const defaultSliderValue = Math.floor(numOptions / 2);
  const [sliderValue, setSliderValue] = useState<number>(defaultSliderValue);
  const [isSliderTouched, setIsSliderTouched] = useState(false);

  useEffect(() => {
    setSliderValue(defaultSliderValue);
  }, [currentQuestionIndex, defaultSliderValue]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
    setIsSliderTouched(true);
  };
  const handleSliderNext = () => {
    onSelectAnswer(options[sliderValue]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  return (
    <Box key={question?.id} sx={{ textAlign: "center", margin: "0 auto" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mb: 4, position: "relative" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            mt: { xs: "30px", sm: "0px" },
            position: "absolute",
            top: "-100px",
            right: { xs: "10px", sm: "-50px" },
          }}
        >
          <FeedbackDialog
            onFeedbackSubmit={handleGivenFeedback}
            diaglogTitle="Please provide feedback on this question"
            buttonType=""
            buttonText={""}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            component="h2"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            {kpis?.find((i) => i.id === question?.kpiId)?.category}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ mb: 8 }}>
        {question?.question}
        {question?.addendum && (
                  <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={3000}
            title={
              <Box sx={{ fontSize: "1rem" }}>
                <em>{question?.addendum}</em>
              </Box>
            }
            arrow
            disableTouchListener={false}
            disableFocusListener={false}
          >
            <InfoOutlinedIcon sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </Typography>
      <Box>
        {/* QUESTION TYPE SLIDER*/}
        {question?.questionType === QUESTION_TYPE.Slider && numOptions > 0 && (
          <Box
            sx={{
              width: {
                xs: "75%",
                sm: "100%",
              },
              margin: "auto",
            }}
          >
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={numOptions - 1}
              valueLabelDisplay="on"
              valueLabelFormat={(index) => options[index]}
              sx={{
                "& .MuiSlider-valueLabel": {
                  width: { xs: "100px" },
                  whiteSpace: "break-spaces",
                },
              }}
            />

            <IconButton
              sx={{ float: "right", mt: 4 }}
              color="primary"
              size="large"
              onClick={handleSliderNext}
            >
              <ArrowCircleRightIcon fontSize="large" />
            </IconButton>
          </Box>
        )}

              {question?.questionType === QUESTION_TYPE.MultipleChoice && (
                  <Grid>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                          {question?.possibleAnswers.map((option, optionIndex) => (
                              <Box
                                  key={optionIndex}
                                  sx={{
                                      flex: '1 1 100%',   // Full width on small screens
                                      '@media (min-width: 600px)': {
                                          flex: '1 1 calc(50% - 16px)', // 50% width for two columns on larger screens
                                      },
                                      padding: 1,
                                  }}
                              >
                                  <ListItem disablePadding>
                                      <ListItemButton role={undefined} dense onClick={() => handleCheckboxChange(option)}>
                                          <ListItemIcon>
                                              <Checkbox
                                                  edge="start"
                                                  value={option}
                                                  tabIndex={-1}
                                                  checked={selectedOptionsCheckbox.includes(option)}
                                              />
                                          </ListItemIcon>
                                          <ListItemText primary={option} />
                                      </ListItemButton>
                                  </ListItem>
                              </Box>
                          ))}
                      </Box>
                  
            {/* Conditional rendering based on selected options */}
            <Grid>
              {selectedOptionsCheckbox.length > 0 ? (
                <IconButton
                  sx={{ float: "right", mt: 4 }}
                  color="primary"
                  size="large"
                  onClick={handleCheckboxNext}
                >
                  <ArrowCircleRightIcon fontSize="large" />
                </IconButton>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleCheckboxNext}
                  sx={{ mr: 2, mt: 4.5, float: "right" }}
                >
                  No Preference
                  <ArrowCircleRightIcon fontSize="large" />
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        {question?.questionType === QUESTION_TYPE.MultipleChoiceRequired && (
          <Grid>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              {question?.possibleAnswers.map((option, optionIndex) => (
                <Box
                  key={optionIndex}
                  sx={{
                    flex: "1 1 100%", // Full width on small screens
                    "@media (min-width: 600px)": {
                      flex: "1 1 calc(50% - 16px)", // 50% width for two columns on larger screens
                    },
                    padding: 1,
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      role={undefined}
                      dense
                      onClick={() => handleCheckboxChange(option)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          value={option}
                          tabIndex={-1}
                          checked={selectedOptionsCheckbox.includes(option)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </ListItemButton>
                  </ListItem>
                </Box>
              ))}
            </Box>

            {/* Conditional rendering based on selected options */}
            <Grid>
              {selectedOptionsCheckbox.length > 0 ? (
                <IconButton
                  sx={{ float: "right", mt: 4 }}
                  color="primary"
                  size="large"
                  onClick={handleCheckboxNext}
                >
                  <ArrowCircleRightIcon fontSize="large" />
                </IconButton>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        )}

        {(question?.questionType === QUESTION_TYPE.Radiobuttontext ||
          question?.questionType === QUESTION_TYPE.Radiobutton) && (
           <Box>
            {question?.possibleAnswers.map((option, optionIndex) => (
              <Box key={optionIndex} sx={{ mb: 2 }}>
                {option.includes("xxx") ? (
                  <Grid
                    container
                    spacing={1}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md="auto"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="h6">
                          {option.replace("xxx", "")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md="auto"
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <input
                        type="text"
                        onChange={(e) =>
                          handleInputChange(
                            option.replace("xxx", "") + e.target.value
                          )
                        }
                        placeholder="Enter your answer"
                        style={{
                          padding: "8px",
                          fontSize: "1.25rem",
                          width: "100%",
                          maxWidth: "300px",
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ fontSize: "1.1rem" }}
                      onClick={() => handleOptionChange(option)}
                    >
                      {option}
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}

        {question?.questionType === QUESTION_TYPE.Writtentext && (
          <input
            type="text"
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder="Enter your answer"
            style={{
              width: "100%",
              padding: "8px",
              fontSize: "1.25rem",
              marginBottom: "16px",
            }}
          />
        )}

        {question?.questionType === QUESTION_TYPE.Number && (
          <input
            type="number"
            onChange={(e) => handleNumberChange(e.target.value)}
            placeholder="Enter a number"
            style={{
              width: "100%",
              padding: "8px",
              fontSize: "1.25rem",
              marginBottom: "16px",
            }}
          />
        )}

        {question?.questionType === QUESTION_TYPE.PointAllocation && (
          <GroupedQuestionComponent
            rows={groupedQuestions}
            totalPoints={+question.possibleAnswers[0]}
            onNext={(graduateSurveyGroupedResult) =>
              handlePointAllocationChanged(graduateSurveyGroupedResult)
            }
          />
        )}
      </Box>

      {(question?.questionType === QUESTION_TYPE.Radiobuttontext ||
        question?.questionType === QUESTION_TYPE.Number ||
        question?.questionType === QUESTION_TYPE.Writtentext) &&
        selectedOptionsCheckbox.length > 0 && (
          <IconButton
            sx={{ float: "right", mt: 4 }}
            color="primary"
            size="large"
            onClick={handleInputNext}
          >
            <ArrowCircleRightIcon fontSize="large" />
          </IconButton>
        )}
    </Box>
  );
};

export default Question;
