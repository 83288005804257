import { GraduateSurveyGroupedResult } from "../../types/GraduateSurveyGroupedResult";
import { GraduateSurveyResult } from "../../types/GraduateSurveyResult";
import { makeRequest } from './config/requestHelper';

const GRADUATE_SURVEY_RESULT_ENDPOINT = 'GraduateSurveyResult';

export const GraduateSurveyResultsAPI = {
    create: async function (
        graduateSurveyResults: GraduateSurveyResult[],
        graduateSurveyGroupResults: GraduateSurveyGroupedResult[],
        cancel: boolean = false
    ) { 
        const graduateSurveyData = {
            graduateSurveyResults: graduateSurveyResults,
            graduateSurveyGroupedResults: graduateSurveyGroupResults,
        };

        try {
            const response = await makeRequest(`${GRADUATE_SURVEY_RESULT_ENDPOINT}/save-results`, {
                method: "POST",
                data: graduateSurveyData,
                requiresAuth: true,
            });

            return response;
        } catch (error) {
           console.error("Something went wrong when handling the request.", error);
        }
    },
};
