import { AuthState } from '../../types/AuthState';
import { AuthAction } from '../../types/AuthAction';
import { AuthActionTypes } from '../../types/AuthActionType';

export const initialAuthState: AuthState = {
    user: null,
    jwtToken: null,
    isAuthenticated: false
  };

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
            return {
                ...state,
                user: action.payload.user,
                jwtToken: action.payload.jwtToken,
                isAuthenticated: action.payload.isAuthenticated
            };
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                user: null,
                jwtToken: null,
                isAuthenticated: false
            };
        default:
            return state;
    }
};
