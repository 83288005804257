import React, { createContext, useReducer, useEffect, useState } from 'react';
import { authReducer } from '../services/reducer/auth-reducer';
import { AuthState } from '../types/AuthState';
import { AuthUtility } from '../services/utils/auth-utils';
import { AuthActionTypes } from '../types/AuthActionType';
import { AuthUser } from '../types/User';

// Initial authentication state
const initialAuthState: AuthState = {
    user: null,
    jwtToken: null,
    isAuthenticated: false
};

// Context interface with state and dispatch function
interface AuthContextProps {
    state: AuthState;
    login: () => void;
    logout: () => void;
    isLoading: boolean;
}

// Create the UserContext with an initial value
export const AuthContext =  createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialAuthState);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = AuthUtility.getJwtToken();
        if(!token) {
            setIsLoading(false);
            return;
        }

        const jwtObject = AuthUtility.parseJwtToken(token);
        if(!jwtObject) {
            setIsLoading(false);
            return;
        }

        const user: AuthUser | null  = AuthUtility.getAuthUserFromJwtToken(jwtObject)
        if (!user) {
            setIsLoading(false);
            return;
        }

        const isAuthenticated = AuthUtility.isAuthenticated(jwtObject)
        if(!isAuthenticated) {
            AuthUtility.removeJwtToken();
            dispatch({ type: AuthActionTypes.LOGOUT });
            setIsLoading(false);
            return;
        }

        dispatch({ type: AuthActionTypes.LOGIN, payload: { user, jwtToken: token, isAuthenticated } });
        setIsLoading(false);
    }, []);

    const login = () => {
        const token = AuthUtility.getJwtToken();
        if(!token)
            return;

        const jwtObject = AuthUtility.parseJwtToken(token);
        if(!jwtObject)
            return;
        
        const user: AuthUser | null = AuthUtility.getAuthUserFromJwtToken(jwtObject);
        if(!user)
            return;

        const isAuthenticated = true;

        dispatch({ type: AuthActionTypes.LOGIN, payload: { user, jwtToken: token, isAuthenticated } });
    };

    const logout = () => {
        AuthUtility.removeJwtToken();
        dispatch({ type: AuthActionTypes.LOGOUT });
    };

    return (
      <AuthContext.Provider value={{ state, login, logout, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};