import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import checklist_icon from "../../public/HomePage/QuestionIcon.svg";

interface FeedbackProps {
  onFeedbackSubmit: (feedback: string) => void;
  diaglogTitle?: string;
  diaglogBody?: string;
  buttonType: "yes" | "no" | "custom" | "";
  buttonText: string;
  buttonProps?: React.ComponentProps<typeof Button>;
}

const FeedbackDialog: React.FC<FeedbackProps> = ({
  onFeedbackSubmit,
  diaglogTitle,
  diaglogBody,
  buttonType,
  buttonText = "",
  buttonProps,
}) => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(e.target.value);
  };

  const handleSubmitFeedback = () => {
    onFeedbackSubmit(feedback);
    handleClose();
  };

  const renderButton = () => {
    let buttonContent;
    switch (buttonType) {
      case "yes":
        buttonContent = (
          <>
            <Typography>I'm interested</Typography>
          </>
        );
        break;
      case "no":
        buttonContent = (
          <>
            <Typography>Not for me</Typography>
          </>
        );
        break;
      case "custom":
        buttonContent = buttonText;
        break;
      default:
        buttonContent = <FeedbackIcon />;
    }

    return (
      <Button onClick={handleOpen} {...buttonProps}>
        {buttonContent}
      </Button>
    );
  };

  return (
    <div>
      {renderButton()}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#FFFBF7",
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "10px",
          }}
        >
          <img
            src={checklist_icon}
            alt="Icon"
            style={{ width: "50px", height: "50px" }}
          />
        </DialogTitle>

        <DialogContent>
          <Typography variant="h5" gutterBottom>
            {diaglogTitle}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {diaglogBody}
          </Typography>

          <TextField
            autoFocus
            margin="dense"
            label="Feedback"
            type="text"
            fullWidth
            value={feedback}
            onChange={handleFeedbackChange}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", marginTop: "20px" }}>
          <Button
            onClick={handleSubmitFeedback}
            variant="contained"
            color="primary"
            sx={{ marginRight: "10px" }}
          >
            Submit
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedbackDialog;
