import "./css/StudentPortalLayout.css";

import React, { useState } from "react";
import PortalSidebar from "../components/student_portal/PortalSidebar";
import UserPortalMain from "../components/student_portal/UserPortalMain";
import { PortalSIdebarMenuItems as PortalMenuItems } from "../enums/PortalSidebarMenuItems";

const UserPortalLayout: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState<string>(
    PortalMenuItems.Dashbaord
  );

  const handleMenuClick = (menu: string) => {
    setSelectedMenu(menu);
  };

  const handleEditCvMenuClick = () => {
    setSelectedMenu(PortalMenuItems.Settings);
  };

  return (
    <div className="layout">
      <PortalSidebar onMenuClick={handleMenuClick} />
      <UserPortalMain
        onEditCvClick={handleEditCvMenuClick}
        selectedMenu={selectedMenu}
      />
    </div>
  );
};

export default UserPortalLayout;
