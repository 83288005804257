export interface GraduateSurveyQuestion {
    id: number;
    question: string;
    questionType: QUESTION_TYPE;
    possibleAnswers: string[];
    addendum: string;
    kpiId: number;
    graduateGroupedQuestionId: number;
}


export enum QUESTION_TYPE {
    Radiobutton = 0,
    MultipleChoice = 1,
    Dropdown = 2,
    Writtentext = 3,
    Number = 4,
    Radiobuttontext = 5,
    PointAllocation = 6,
    Slider = 7,
    MultipleChoiceRequired = 8
}