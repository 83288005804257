import React from 'react';
import { Box, Button, Typography, Link, Container } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import BlueBackground from '../public/Bluebackground.svg';
import { UrlUtility } from '../services/utils/url-utils';
import { useLocation, useNavigate } from 'react-router-dom';

const GOOGLE_AUTHENTICATION_ENDPOINT = 'GoogleAuthentication';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Compute 'from' path or fallback to a default route
    const from =
        location.state?.from?.pathname ||
        new URLSearchParams(window.location.search).get('ReturnUrl') ||
        '/';

    // Redirects to Google login
    const handleLoginRedirect = () => {
        try {
            const loginUrl = `${UrlUtility.getBaseApiUrl()}/${GOOGLE_AUTHENTICATION_ENDPOINT}/login`;
            const encodedReturnUrl = encodeURIComponent(window.location.origin + from);
            window.location.href = `${loginUrl}?ReturnUrl=${encodedReturnUrl}`;
        } catch (error) {
            console.error('Error redirecting to login:', error);
        }
    };

    return (
        <Container maxWidth="xl">
            {/* Background Image */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: -2,
                    width: '100%',
                }}
            >
                <img
                    src={BlueBackground}
                    alt="Blue background"
                    style={{ width: '100%', height: 'auto' }}
                />
            </Box>

            {/* Login Box */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Box
                    bgcolor="white"
                    p={4}
                    borderRadius="12px"
                    boxShadow={3}
                    maxWidth="400px"
                    width="100%"
                    textAlign="center"
                >
                    <Typography variant="h4" fontWeight="bold" mb={2}>
                        Hello there!
                    </Typography>

                    {/* Google Login Button */}
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        startIcon={<GoogleIcon />}
                        onClick={handleLoginRedirect}
                        sx={{ py: 1.5 }}
                        aria-label="Login with Google"
                    >
                        Login with Google
                    </Button>

                    {/* Terms and Privacy Links */}
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        By clicking login I agree with{' '}
                        <Link href="/PrivacyPolicy" underline="hover">
                            privacy policy
                        </Link>{' '}
                        and{' '}
                        <Link href="/TermsOfService" underline="hover">
                            terms of service
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
