import { AuthUtility } from '../../utils/auth-utils';
import api from './axiosConfig';
import { AxiosRequestConfig } from 'axios';

interface RequestOptions extends AxiosRequestConfig {
  requiresAuth?: boolean; // Custom flag to include authorization header or not
}

export const makeRequest = async (url: string, options: RequestOptions) => {
  const { requiresAuth, ...axiosOptions } = options;

  if (requiresAuth) {
    const token = AuthUtility.getJwtToken();

    if (token) {
      axiosOptions.headers = {
        ...axiosOptions.headers,
        Authorization: `Bearer ${token}`,
      };
    }
  }

  try {
    const response = await api({
      url,
      ...axiosOptions,
    });
    return response;
  } catch (error) {
    console.error('Error making request:', error);
    throw error;
  }
};
