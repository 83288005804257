import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { ThemeProvider } from '@mui/system';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Card, CardContent, CardActions } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import LinearProgress from '@mui/material/LinearProgress';

import BlueBackground from '../public/Bluebackground.svg';

import { GraduateSurveyQuestion } from '../types/GraduateSurveyQuestion';
import { Kpi } from '../types/Kpi';
import Question from '../components/survey/QuestionComponent';
import QuestionCounter from '../components/survey/QuestionCounterComponent';
import { GraduateSurveyQuestionsAPI } from '../services/api/graduate-survey-questions';
import { KpiAPI } from '../services/api/kpis';
import { GraduateSurveyResult } from "../types/GraduateSurveyResult";
import { GraduateSurveyResultsAPI } from '../services/api/graduate-survey-results';
import { GraduateSurveyGroupedResult } from '../types/GraduateSurveyGroupedResult';
import { GraduateSurveyGroupedQuestionsAPI } from '../services/api/graduate-survey-grouped-questions';
import { GraduateSurveyGroupedQuestion } from '../types/GraduateSurveyGroupedQuestion';

export default function SurveyPage() {
    const navigate = useNavigate();
    const kpi: Kpi[] = [{ id: 0, kpiValue: 'none', category: 'noCat' }];
    const [questions, setQuestions] = useState<GraduateSurveyQuestion[] | null>(null);
    const [groupedQuestions, setGroupedQuestions] = useState<GraduateSurveyGroupedQuestion[] | null>(null);
    const [kpis, setKpis] = useState<Kpi[]>(kpi);
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOptionsCheckbox, setSelectedCheckboxOptions] = useState<string[]>([]);
    const [feedbackValue, setFeedbackValue] = useState<string[]>([]);
    const [answers, setAnswers] = useState<string[]>([]);
    const [groupedAnswers, setGroupedAnswers] = useState<GraduateSurveyGroupedResult[]>([]);
    const [questionTimer, setQuestionTimer] = useState<number[]>([]);
    const [startTimer] = useState(new Date().getTime());
    const [isSubmitting, setIsSubmitting] = useState(false);

    // TODO: combine all these calls into one endpoint call. This will be more efficient.
    useEffect(() => {
        const fetchSurveyQuestions = async () => {
            try {
                const data = await GraduateSurveyQuestionsAPI.getAll(false);
                setQuestions(data);
                setFeedbackValue(Array(data.length).fill(''));
                setAnswers(Array(data.length).fill(''));
                setQuestionTimer(Array(data.length).fill(0)); // Initialize with zeros
                fetchGroupedQuestions();
            } catch (error) {
                console.error('Error fetching survey questions:', error);
            }
        };

        const fetchGroupedQuestions = async () => {
            try {
                const data = await GraduateSurveyGroupedQuestionsAPI.getAll(false);
                setGroupedQuestions(data);
            } catch (error) {
                console.error('Error fetching grouped questions:', error);
            }
        };

        const fetchKPIs = async () => {
            try {
                const data = await KpiAPI.getAll(false);
                setKpis(data);
                fetchSurveyQuestions();
            } catch (error) {
                console.error('Error fetching KPIs:', error);
            }
        };

        if (!retrievedData) {
            setRetrievedData(true);
            fetchKPIs();
        }
    }, [retrievedData]);

    const handleOptionChange = (questionIndex: number, selectedValue: string) => {
        const newAnswers = [...answers];
        newAnswers[questionIndex] = selectedValue;
        setAnswers(newAnswers);

        const newQuestionTimer = [...questionTimer];
        newQuestionTimer[questionIndex] = new Date().getTime() - startTimer;
        setQuestionTimer(newQuestionTimer);

        setCurrentQuestionIndex(questionIndex + 1);
    };

    const handleGroupedOptionChange = (groupedResults: GraduateSurveyGroupedResult[]) => {
        const groupedCombinedResults = [...groupedAnswers]; // Clone the current state

        groupedResults.forEach((groupedResult) => {
            const existingIndex = groupedAnswers.findIndex(
                (answer) => answer.graduateSurveyGroupedQuestionId === groupedResult.graduateSurveyGroupedQuestionId
            );

            if (existingIndex !== -1) {
                groupedCombinedResults[existingIndex] = groupedResult; // Update existing result
            } else {
                groupedCombinedResults.push(groupedResult); // Add new result
            }
        });
        setGroupedAnswers(groupedCombinedResults);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
    };

    const handleOptionFeedback = (questionIndex: number, feedback: string) => {
        const newFeedback = [...feedbackValue];
        newFeedback[questionIndex] = feedback;
        setFeedbackValue(newFeedback);
    };

    const handlePreviousQuestion = () => {
        setSelectedCheckboxOptions([]);
        setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setTimeout(async () => {
            try {
                const surveyResults: GraduateSurveyResult[] = questions!.map((question, index) => ({
                    graduateSurveyQuestionId: question.id,
                    answer: answers[index],
                    timer: questionTimer[index],
                    feedback: feedbackValue[index],
                }));

                const response: AxiosResponse<any> | undefined = await GraduateSurveyResultsAPI.create(surveyResults, groupedAnswers, false);

                if (response && response.status < 399) {
                    navigate('/survey-results');
                } else {
                    console.error('Failed to submit answers:', response?.statusText);
                }
            } catch (error: unknown) {
                if (error instanceof Error) {
                    console.error('Error submitting answers:', error.message);
                } else {
                    console.error('Unexpected error:', error);
                }
            }
        }, 2500);
    };

    // TODO: seems like a quick fix. Should move handle submit into a component (or at least not have it in useEffect).
    useEffect(() => {
        if (questions !== null && currentQuestionIndex >= questions.length) {
            handleSubmit();
        }
    }, [currentQuestionIndex, questions]);
    // Determine the correct groupedQuestions based on the current question
    const currentGroupedQuestions = questions && groupedQuestions && questions[currentQuestionIndex] && questions[currentQuestionIndex]?.graduateGroupedQuestionId !== 0
        ? groupedQuestions.filter(gq => gq.groupId === questions[currentQuestionIndex].graduateGroupedQuestionId)
        : [];

    if (questions === null || isSubmitting) {
        return (
            <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {questions === null ? 'Loading questions...' : 'Calculating results...'}
                </Typography>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: -2,
                    width: '100%',
                }}
            >
                <img src={BlueBackground} alt="Blue background" style={{ width: '100%', height: 'auto' }} />
            </Box>
            <Container maxWidth="xl" sx={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 0, paddingRight:0, display: 'flex', flexDirection: 'column', minHeight: '80vh' }}>
                <Box sx={{ flexGrow: 1, margin:'auto'}}>
                    <Card
                        sx={{
                            mt: { xs: 10, sm: 15 },
                            mb: 3,
                            pt: { xs: 1, sm: 8 },
                            pb: { xs: 1, sm: 8 },
                            pr: { xs: 0, sm: 8 },
                            pl: { xs: 0, sm: 8 },
                            maxWidth: { xs: '100%', sm: '850px' },
                            minWidth: { xs: '100%', md: '850px' },
                            border: { xs: 'none', sm: '1px solid rgba(0, 0, 0, 0.12)' },
                            boxShadow: { xs: 'none', sm: 'default' },
                            position: 'relative',
                        }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={((currentQuestionIndex + 1) / (questions.length || 1)) * 100}
                            sx={{
                                height: 4,
                                borderRadius: 2,
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                position: 'absolute', 
                                top: 0, 
                                left: 0, 
                                right: 0, 
                            }}
                        />
                        <CardContent sx={{
                            pt: { xs: 1},
                            pb: { xs: 1},
                            pr: { xs: 0},
                            pl: { xs: 0},
}}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <QuestionCounter
                                    questions={questions}
                                    kpis={kpis}
                                    currentQuestionIndex={currentQuestionIndex}
                                />
                            </Box>
                            {currentQuestionIndex < questions.length ? (
                                <Grid item xs={12} sm={7}>
                                    <Question
                                        question={questions[currentQuestionIndex]}
                                        kpis={kpis}
                                        onSelectAnswer={(selectedValue) => handleOptionChange(currentQuestionIndex, selectedValue)}
                                        onSelectGroupedAnswer={(groupedResults) => handleGroupedOptionChange(groupedResults)}
                                        handleGivenFeedback={(feedback) => handleOptionFeedback(currentQuestionIndex, feedback)}
                                        currentQuestionIndex={currentQuestionIndex}
                                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                                        selectedOptionsCheckbox={selectedOptionsCheckbox}
                                        setSelectedCheckboxOptions={setSelectedCheckboxOptions}
                                        groupedQuestions={currentGroupedQuestions}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} textAlign="center" sx={{ mt: 3 }}>
                                    <Typography variant="h6">Alle vragen beantwoord!</Typography>
                                </Grid>
                            )}
                        </CardContent>

                       
                        <CardActions sx={{ justifyContent: 'space-between' }}>
                            {currentQuestionIndex > 0 && (
                                <IconButton
                                    sx={{ float: 'left', mt: 2 }}
                                    color="primary"
                                    size="large"
                                    onClick={handlePreviousQuestion}
                                >
                                    <ArrowCircleLeftIcon fontSize="large" />
                                </IconButton>
                            )}
                        </CardActions>
                    </Card>
                </Box>
            </Container>
        </>
    );
}
