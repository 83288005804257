import api from "./config/axiosConfig"

const GRADUATE_SURVEY_GROUPED_QUESTION_ENDPOINT = 'GraduateSurveyGroupedQuestion'

export const GraduateSurveyGroupedQuestionsAPI = {
  getAll: async function (cancel: boolean | false) {
    const response = await api.request({
        url: `/${GRADUATE_SURVEY_GROUPED_QUESTION_ENDPOINT}`,
      method: "GET"
    })

    return response.data
  },
}