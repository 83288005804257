import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import companyLogo from '../../public/CompanyLogos/gg2.png';
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

interface NavBarProps {
    window?: () => Window;
}

const navItems = [
    { label: 'COMPANY PORTAL', path: '/portal' },
    { label: 'STUDENT LOGIN', path: '/login' }
];

function NavBar(props: NavBarProps) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [scrolled, setScrolled] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    // Handle scroll events
    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', p: 2 }}>
            <Grid container justifyContent="center">
                <Grid item>
                    <img
                        src={companyLogo}
                        alt="GradGuide"
                        style={{ maxWidth: '100px', height: 'auto', paddingTop: '16px', paddingBottom: '16px' }} // Top and bottom padding
                    />
                </Grid>
            </Grid>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton component={Link} to={item.path} sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <header className="navbar">
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    component="nav"
                    sx={{
                        width: '100%',
                        backgroundColor: scrolled ? '#fff' : 'transparent',
                        boxShadow: scrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
                        transition: 'background-color 0.3s, box-shadow 0.3s',
                        py: 2
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mx: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Container>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item sx={{ display: { sm: 'none', xs: 'block' } }}>
                                    <Link to="/" style={{ textDecoration: 'none' }}>
                                        <img
                                            src={companyLogo}
                                            alt="GradGuide"
                                            style={{
                                                maxWidth: scrolled ? '80px' : '120px',
                                                height: 'auto',
                                                position: 'absolute',
                                                top: scrolled ? '3px' : '20px',
                                                right: '50px',
                                            }}
                                        />
                                    </Link>
                                </Grid>
                                <Grid item sx={{ display: { sm: 'block', xs: 'none' } }}>
                                    <Link to="/" style={{ textDecoration: 'none' }}>
                                        <img
                                            src={companyLogo}
                                            alt="GradGuide"
                                            style={{
                                                maxWidth: scrolled ? '100px' : '164px',
                                                height: 'auto',
                                                transition: 'max-width 0.3s',
                                            }}
                                        />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        {navItems.map((item) => (
                                            <Button key={item.label} component={Link} to={item.path} sx={{ color: '#212832' }}>
                                                {item.label}
                                            </Button>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: 240,
                            },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Box>  
        </header>
    );
}

export default NavBar;
