import { format } from "date-fns";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { UserNotification } from "../../types/UserNotification";

interface NotificationsTableProps {
  isLoading: boolean;
  notifications: UserNotification[];
}

const NotificationsTable: React.FC<NotificationsTableProps> = (props) => {
  return (
    <Grid container spacing={3} sx={{ mt: 4 }} alignItems="center">
      {props.isLoading ? (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Loading...
          </Typography>
        </Grid>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" color="textSecondary">
                    Created
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textSecondary">
                    Message
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.notifications.map((notification) => (
                <TableRow key={notification.id}>
                  <TableCell>
                    <Typography variant="body1">
                      {format(
                        notification.createdDateTime,
                        "yyyy-MM-dd HH:mm:ss"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {notification.message}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default NotificationsTable;
