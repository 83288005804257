import "./css/SummaryCard.css";

import React from "react";

interface SummaryCardProps {
  count: number;
  label: string;
  color: string;
  icon: string;
  onClick: () => void;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  count,
  label,
  color,
  icon,
  onClick,
}) => {
  return (
    <div className={`summary-card ${color}`} onClick={onClick}>
      <p className="count">{count}</p>
      <p className="label">{label}</p>
    </div>
  );
};

export default SummaryCard;
