import { useEffect, useState } from "react";

import { Container, Grid, Typography, Box } from "@mui/material";

import BlueBackground from "../public/Bluebackground.svg";

import { GraduateCompanyResultAPI } from "../services/api/graduate-company-result";
import { GraduateCompanyResult } from "../types/GraduateCompanyResult";
import NavBar from "../components/general/NavBar";
import CompanyMatchCard from "../components/company_match/CompanyMatchCard";

export default function ResultPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState<GraduateCompanyResult[] | null>(
    null
  );

  useEffect(() => {
    fetchGraduateCompanyResults();
  }, []);

  const fetchGraduateCompanyResults = async () => {
    try {
      const response = await GraduateCompanyResultAPI.getTopSalary();
        setCompanies(response?.data);
       
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const imageList = companies?.map((companyResult) => {
    try {
      const companyLogoPath = require(`../public/CompanyLogos/${companyResult.company.name}.png`);
      return { src: companyLogoPath, name: companyResult.company.name };
    } catch (error) {
      console.error(
        "Error loading image for:",
        companyResult.company.name,
        error
      );
      return { src: null, name: companyResult.company.name };
    }
  });

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -2,
          width: "100%",
        }}
      >
        <img
          src={BlueBackground}
          alt="Blue background"
          style={{ width: "100%", height: "auto" }}
        />
      </Box>
      <Grid container spacing={3} sx={{ mt: 4 }} alignItems="center">
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h4" align="center" sx={{ mt: 10, mb: 3 }}>
              Your recommended companies!
            </Typography>
          </Grid>
        </Grid>

        {isLoading ? (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Loading...
            </Typography>
          </Grid>
        ) : (
          companies?.map((companyResult, index) => (
            <CompanyMatchCard
              key={index}
              companyResult={companyResult}
              imageSrc={
                imageList
                  ? imageList[index]
                  : { src: null, name: companyResult.company.name }
              }
            />
          ))
        )}
      </Grid>
    </Container>
  );
}
