import "./css/StudentSidebarComponent.css";

import React from "react";
import { useAuth } from "../../services/hooks/useAuth";
import { PortalSIdebarMenuItems as PortalSidebarMenuItems } from "../../enums/PortalSidebarMenuItems";

interface PortalSidebarProps {
  onMenuClick: (menu: string) => void;
}

const PortalSidebar: React.FC<PortalSidebarProps> = ({ onMenuClick }) => {
  const { state, logout } = useAuth();

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>
          Hello, {state.user?.givenName} {state.user?.surnName}
        </h2>
      </div>
      <ul className="sidebar-menu">
        <li onClick={() => onMenuClick(PortalSidebarMenuItems.Dashbaord)}>
          {PortalSidebarMenuItems.Dashbaord}
        </li>
        <li onClick={() => onMenuClick(PortalSidebarMenuItems.Matches)}>
          {PortalSidebarMenuItems.Matches}
        </li>
        <li onClick={() => onMenuClick(PortalSidebarMenuItems.Settings)}>
          {PortalSidebarMenuItems.Settings}
        </li>
      </ul>
      <div className="logout" onClick={() => logout()}>
        {PortalSidebarMenuItems.Logout}
      </div>
    </div>
  );
};

export default PortalSidebar;
