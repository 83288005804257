import "./css/SummaryCards.css";

import React from "react";
import SummaryCard from "./SummaryCard";
import { UserDashboardCategory } from "../../enums/UserDashboardCategory";

interface SummaryCardsProps {
  bookmarked: number;
  applied: number;
  notifications: number;
  onCardClick: (category: string) => void;
}

const SummaryCards: React.FC<SummaryCardsProps> = ({
  bookmarked,
  applied,
  notifications,
  onCardClick,
}) => {
  return (
    <div className="summary-cards">
      <SummaryCard
        count={bookmarked}
        label={UserDashboardCategory.Bookedmarked}
        color="blue"
        icon="Bookmark"
        onClick={() => onCardClick(UserDashboardCategory.Bookedmarked)}
      />
      <SummaryCard
        count={applied}
        label={UserDashboardCategory.Applied}
        color="yellow"
        icon="Thumbsup"
        onClick={() => onCardClick(UserDashboardCategory.Applied)}
      />
      <SummaryCard
        count={notifications}
        label={UserDashboardCategory.Notifications}
        color="green"
        icon="File"
        onClick={() => onCardClick(UserDashboardCategory.Notifications)}
      />
    </div>
  );
};

export default SummaryCards;
