import './App.css'
import { ThemeProvider } from "@mui/material/styles"
import { CssBaseline } from "@mui/material";
import Routes from './Routes';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import MainTheme from './themes/MainTheme';
import MainLayout from './layouts/MainLayout';

function App() {
  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={MainTheme}>
          <BrowserRouter>
            <MainLayout>
              <Routes />
              <CssBaseline />
            </MainLayout>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </> 
  )
}
export default App
