import {
  Grid,
  Typography,
  Box,
  Tooltip,
  useMediaQuery,
  useTheme,
  Theme,
} from "@mui/material";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import SalaryIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import CompanyTypeIcon from "@mui/icons-material/BusinessOutlined";
import CompanyBusinessTypeIcon from "@mui/icons-material/BusinessCenterOutlined";

import { ImageSrc } from "../../types/ImageSrc";
import { GraduateCompanyResult } from "../../types/GraduateCompanyResult";

interface CompanyDetailsProps {
  imageSrc: ImageSrc;
  companyResult: GraduateCompanyResult;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = (props) => {
  const theme = useTheme<Theme>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid item xs={12} onClick={props.onClick} style={{ cursor: "pointer" }}>
        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
          {props.imageSrc.src ? (
            <img
              style={{ maxHeight: "60px", maxWidth: "200px" }}
              src={props.imageSrc.src}
              alt={`${props.imageSrc.name}`}
            />
          ) : (
            <Typography variant="h4">{props.imageSrc.name}</Typography>
          )}
        </Box>

        <Grid item>
          <Box display="flex" alignItems="flex-start" flexWrap="wrap">
            <Box
              display="flex"
              alignItems="flex-start"
              sx={{ mr: 3, flexShrink: 0 }}
            >
              <Tooltip
                title="Location"
                placement="bottom"
                arrow
                disableHoverListener={isSmallScreen}
                disableTouchListener={!isSmallScreen}
              >
                <LocationIcon color="secondary" sx={{ mr: 1 }} />
              </Tooltip>
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: isSmallScreen ? "normal" : "nowrap",
                  marginTop: "3px",
                  maxWidth: isSmallScreen ? "240px" : "100%",
                }}
              >
                {props.companyResult.company.location}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-start"
              sx={{ mr: 3, flexShrink: 0 }}
            >
              <Tooltip
                title="Estimate salary per month"
                placement="bottom"
                arrow
                disableHoverListener={isSmallScreen}
                disableTouchListener={!isSmallScreen}
              >
                <SalaryIcon color="secondary" sx={{ mr: 1 }} />
              </Tooltip>
              <Typography
                variant="subtitle2"
                sx={{ whiteSpace: isSmallScreen ? "normal" : "nowrap" }}
              >
                {props.companyResult.company.salary}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box
              display="flex"
              alignItems="flex-start"
              sx={{ mr: 3, flexShrink: 0 }}
            >
              <Tooltip
                title="Company Size"
                placement="bottom"
                arrow
                disableHoverListener={isSmallScreen}
                disableTouchListener={!isSmallScreen}
              >
                <CompanyTypeIcon color="secondary" sx={{ mr: 1 }} />
              </Tooltip>
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: isSmallScreen ? "normal" : "nowrap",
                  marginTop: "3px",
                  maxWidth: isSmallScreen ? "240px" : "100%",
                }}
              >
                {props.companyResult.company.size}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-start"
              sx={{ mr: 3, flexShrink: 0 }}
            >
              <Tooltip
                title="Industry Type"
                placement="bottom"
                arrow
                disableHoverListener={isSmallScreen}
                disableTouchListener={!isSmallScreen}
              >
                <CompanyBusinessTypeIcon color="secondary" sx={{ mr: 1 }} />
              </Tooltip>
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: isSmallScreen ? "normal" : "nowrap",
                  marginTop: "3px",
                  maxWidth: isSmallScreen ? "240px" : "100%",
                }}
              >
                {props.companyResult.company.industry}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 3 }}>
            {props.companyResult.company?.description}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetails;
