import React, { useEffect, useState } from "react";
import { GraduateSurveyQuestion } from "../../types/GraduateSurveyQuestion";
import { Kpi } from "../../types/Kpi";
import { Typography } from "@mui/material";

interface QuestionCounterProps {
  questions: GraduateSurveyQuestion[];
  kpis: Kpi[];
  currentQuestionIndex: number;
}

const QuestionCounter: React.FC<QuestionCounterProps> = ({
  questions,
  kpis,
  currentQuestionIndex,
}) => {
  const [counterNormalMax, setIndexNormalMaxQuestions] = useState(-1);
  const [counterPersonalMax, setIndexPersonalMaxQuestions] = useState(-1);
  const personalQuestionsNames = [
    "Student characteristics",
    "personal question",
  ];

  useEffect(() => {
    if (counterNormalMax === -1 && questions.length > 1) {
      const kpiIdsPersonalQuestion = kpis
        .filter((e) => personalQuestionsNames.includes(e.category))
        .map((kpi) => kpi.id);

      if (kpiIdsPersonalQuestion.length > 0) {
        const questionsPersonalFiltered = questions.filter((q) =>
          kpiIdsPersonalQuestion.includes(q.kpiId)
        );
        setIndexPersonalMaxQuestions(questionsPersonalFiltered.length + 1);
        setIndexNormalMaxQuestions(
          questions.length - questionsPersonalFiltered.length
        );
      } else {
        setIndexNormalMaxQuestions(questions.length);
        setIndexPersonalMaxQuestions(0);
      }
    }
  }, [counterNormalMax, questions, kpis]);

  // Define a variable to determine visibility
  const isCounterVisible = counterNormalMax >= currentQuestionIndex + 1;

  return (
    <Typography
      variant="subtitle2"
      sx={{
        mb: 5,
        fontSize: "18px",
        visibility: isCounterVisible ? "visible" : "hidden", // Hide or show based on condition
      }}
    >
      <span>
        {currentQuestionIndex + 1}/{counterNormalMax}
      </span>
    </Typography>
  );
};

export default QuestionCounter;
