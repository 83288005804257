import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../services/hooks/useAuth';

interface PrivateRouteProps {
  allowedRoles: string[];
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ allowedRoles, children }) => {
  const { state, isLoading } = useAuth();
  const location = useLocation();
  
  if(isLoading) {
    return <div>loading...</div>
  }

  if (!state.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  var userRoles = state.user?.roles
  if (userRoles && userRoles.some(role => allowedRoles.includes(role))) {
    return children;
  }

  return <Navigate to="/unauthorized" />;
};

export default PrivateRoute;
