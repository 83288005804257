import { makeRequest } from "./config/requestHelper"

const KPI_ENDPOINT = 'Kpi'

export const KpiAPI = {
  getAll: async function (cancel: boolean | false) {
    try {
      const response = await makeRequest(KPI_ENDPOINT, {
        method: 'GET',
        requiresAuth: true
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  },
}