import { useState } from 'react';

import { CompanyAPI } from '../services/api/company';

import Container from '@mui/material/Container';
import Typography  from '@mui/material/Typography';

export default function CompaniesPage() {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null);

  const importCompanies = (formData: FormData) => {
    setIsLoading(true)
    
    CompanyAPI.import(formData, false)
    .catch(e =>  {
      console.log(e)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      importCompanies(formData)
    }
  };

  return (
    <Container>
      <Typography>Companies</Typography>
      <div>
        <label htmlFor="file" className="sr-only">
          Choose a file to import: 
        </label>
        <input id="file" type="file" onChange={handleFileChange} />
      </div>
      {file && (
        <section>
          File details:
          <ul>
            <li>Name: {file.name}</li>
            <li>Type: {file.type}</li>
            <li>Size: {file.size} bytes</li>
          </ul>
        </section>
      )}

      {file && <button onClick={handleUpload}>Upload a file</button>}
    </Container>
  )
}
