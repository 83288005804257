import React, { useState, useEffect } from "react";
import { GraduateSurveyGroupedQuestion } from "../../types/GraduateSurveyGroupedQuestion";
import { GraduateSurveyGroupedResult } from "../../types/GraduateSurveyGroupedResult";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import IconButton from "@mui/material/IconButton";
import { Tooltip, useTheme, Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";

interface GroupedQuestionComponentProps {
  rows: GraduateSurveyGroupedQuestion[];
  totalPoints: number;
  onNext: (groupedResults: GraduateSurveyGroupedResult[]) => void;
}

const GroupedQuestionComponent: React.FC<GroupedQuestionComponentProps> = ({
  rows,
  totalPoints,
  onNext,
}) => {
  const [groupedResults, setGroupedResults] = useState<
    GraduateSurveyGroupedResult[]
  >(
    rows.map((row) => ({
      graduateSurveyGroupedQuestionId: row.id,
      answer: 0,
    }))
  );

  const [hasInteracted, setHasInteracted] = useState<boolean[]>(
    new Array(rows.length).fill(false)
  );

  const handleNumberChange = (index: number, value: string) => {
    const newPoints = parseInt(value, 10) || 0;

    // Prevent negative values
    if (newPoints < 0) {
      alert("Points cannot be negative.");
      return;
    }

    const currentPointsSum = groupedResults.reduce(
      (acc, result, i) => acc + (i === index ? 0 : result.answer),
      0
    );

    // Ensure the total allocated points do not exceed the totalPoints
    if (currentPointsSum + newPoints > totalPoints) {
      alert(`Total points cannot exceed ${totalPoints}.`);
      return;
    }

    const updatedResults = groupedResults.map((result, i) =>
      i === index ? { ...result, answer: newPoints } : result
    );
    setGroupedResults(updatedResults);

    // Mark that the user has interacted with this specific input
    const newHasInteracted = [...hasInteracted];
    newHasInteracted[index] = true;
    setHasInteracted(newHasInteracted);
  };

  useEffect(() => {
    const updatedResults = rows.map((row, index) => ({
      graduateSurveyGroupedQuestionId: row.id,
      answer: groupedResults[index]?.answer || 0,
    }));
    setGroupedResults(updatedResults);
  }, [rows]);

  const pointsRemaining =
    totalPoints -
    groupedResults.reduce((acc, result) => acc + result.answer, 0);

  const handleNext = () => {
    onNext(groupedResults);
  };

  const theme = useTheme<Theme>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ position: "relative" }}>
      <p>Points Remaining: {pointsRemaining}</p>
      {rows.map((row, index) => (
        <div key={row.id} style={styles.row}>
          <input
            type="number"
            value={
              hasInteracted[index]
                ? groupedResults[index]?.answer !== 0
                  ? groupedResults[index]?.answer.toString()
                  : ""
                : ""
            }
            onChange={(e) => handleNumberChange(index, e.target.value)}
            style={styles.input}
            placeholder="0"
          />
          <Typography
            sx={{
              textAlign: "left",
              fontSize: {
                xs: "14px",
                sm: "inherit",
              },
            }}
          >
            {row.name}
          </Typography>
          {row.addendum && (
            <Tooltip
              title={row.addendum}
              placement="bottom"
              arrow
              enterTouchDelay={0}
              leaveTouchDelay={3000}
            >
              <InfoOutlinedIcon sx={{ mr: 1, marginLeft: "auto" }} />
            </Tooltip>
          )}
        </div>
      ))}
      <IconButton
        sx={{ float: "right", mt: 4 }}
        color="primary"
        size="large"
        onClick={handleNext}
        disabled={pointsRemaining !== 0}
      >
        <ArrowCircleRightIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

const styles = {
  row: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    padding: "10px",
    margin: "5px 0",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
  },
  input: {
    width: "80px",
    height: "40px",
    marginRight: "10px",
    padding: "5px",
    textAlign: "center" as "center",
    fontSize: "16px",
  },
  label: {
    flex: 1,
  },
  infoIcon: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
  },
} as const;

export default GroupedQuestionComponent;
