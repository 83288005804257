import api from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtil"

const COMPANY_ENDPOINT = 'Company'

export const CompanyAPI = {
  import: async function (file: FormData, cancel: boolean | false) {
    await api.request({
      url: `/${COMPANY_ENDPOINT}/import`,
      method: "POST",
      data: file
      //signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    })
  }
}