import * as React from 'react';
import { useEffect, useState } from 'react';

import Container from '@mui/material/Container';
import Typography  from '@mui/material/Typography';

import { CompanyValuesAPI } from '../services/api/company-values';
import { CompanyValue } from '../types/CompanyValues';

export default function CompanyValuesPage() {
  const [companyValues, setCompanyValues] = useState<CompanyValue[]>([])
  const [companyValue, setCompanyValue] = useState<CompanyValue | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    // fetchCompanyValues()
  }, [])
 
  const fetchCompanyValues = () => {
    setIsLoading(true)

    CompanyValuesAPI.getAll(false)
    .then(data => {
      setCompanyValues(data)
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      setIsLoading(false)
    })

    setTimeout(() => {}, 5000)
  }

  const updateCompanyValue = () => {
    setIsLoading(true)

    if(companyValue) {
      CompanyValuesAPI.update(companyValue, false)
      .catch(e => {
        console.log(e)
      })
      .then(() => {
        setIsLoading(false)
      })
    }
  }

  const createCompanyValue = () => {
    setIsLoading(true)

    if(companyValue) {
      CompanyValuesAPI.create(companyValue, false)
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
    }
  }

  const delteCompanyValue = (id: number) => {
    setIsLoading(true)

    CompanyValuesAPI.delete(id, false)
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const importCompanyValues = (formData: FormData) => {
    setIsLoading(true)
    
    CompanyValuesAPI.import(formData, false)
    .catch(e =>  {
      console.log(e)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      importCompanyValues(formData)
    }
  };

  return (
    <Container>
      <Typography>Company values</Typography>
      <div>
        <label htmlFor="file" className="sr-only">
          Choose a file to impoty: 
        </label>
        <input id="file" type="file" onChange={handleFileChange} />
      </div>
      {file && (
        <section>
          File details:
          <ul>
            <li>Name: {file.name}</li>
            <li>Type: {file.type}</li>
            <li>Size: {file.size} bytes</li>
          </ul>
        </section>
      )}

      {file && <button onClick={handleUpload}>Upload a file</button>}
    </Container>
  )
}
