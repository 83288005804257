import Typography from '@mui/material/Typography';

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="left">
      {'© '}
       GradGuide
      {' '}
      {new Date().getFullYear()}. All rights reserved.
    </Typography>
  );
}
