import { Card, Grid, Typography, Button } from "@mui/material";
import { ImageSrc } from "../../types/ImageSrc";
import CompanyDetails from "./CompanyDetailsComponent";
import { GraduateCompanyResult } from "../../types/GraduateCompanyResult";
import JobOffersList from "./JobOffersList";
import CompanyMatchFeedback from "./CompanyMatchFeedbackComponent";
import { useNavigate } from "react-router-dom";

interface CompanyMatchCardProps {
  imageSrc: ImageSrc;
  companyResult: GraduateCompanyResult;
}

const CompanyMatchCard: React.FC<CompanyMatchCardProps> = (props) => {
  const navigate = useNavigate();

  const handleCardClick = (
    companyId: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    if ((event.target as HTMLElement).tagName !== "BUTTON") {
      navigate(`/company/${companyId}`);
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        padding: 3,
        maxWidth: 1000,
        margin: "0 auto",
        borderRadius: 3,
        mb: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <CompanyDetails
            companyResult={props.companyResult}
            imageSrc={props.imageSrc}
            onClick={(event) =>
              handleCardClick(props.companyResult.company.id, event)
            }
          />
          {props.companyResult.graduateCompanyJobOffers?.jobOffers &&
            props.companyResult.graduateCompanyJobOffers?.jobOffers.length >
              0 && (
              <>
                          <Typography variant="subtitle2"
                              mt={3} mb={2}
                >
                  Active job offers that fit your profile:
                </Typography>
                <JobOffersList
                  companyJobOffers={
                    props.companyResult.graduateCompanyJobOffers
                  }
                />
              </>
            )}
                  <Button
                      sx={{marginTop:"16px", marginBottom:"16px", padding:"0px", fontWeight:"bold"}}
               variant="text"
               color="primary"
               onClick={() => navigate(`/company/${props.companyResult.company.id}`)}  >
               Find more company info here!
             </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            marginTop: "auto",
          }}
        >
          <CompanyMatchFeedback companyResult={props.companyResult} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CompanyMatchCard;
