import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { JobOffer } from "../../types/JobOffer";
import React, { useState } from "react";
import { Box } from "@mui/system";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material/styles";

interface JobOffersListItemProps {
  index: React.Key;
  jobOffer: JobOffer;
}

const JobOffersListItem: React.FC<JobOffersListItemProps> = ({ jobOffer }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    };

  const theme = useTheme();

  return (
    <>
      <ListItem sx={{ padding: "0" }} onClick={handleToggle}>
              <ListItemIcon
                  sx={{
                      minWidth: "30px",
                      maxWidth: "30px",
                      height:"30px",
                      backgroundColor: theme.palette.primary.main, 
                      borderRadius: "4px",  
                      display: "flex", 
                      justifyContent: "center",
                      alignItems: "center",
                      margin:'2px 8px 2px 0px'
                  }}
              >
                  {open ? (
                      <ArrowDropDownIcon sx={{ color: "white" }} /> 
                  ) : (
                      <ArrowRightIcon sx={{ color: "white" }} /> 
                  )}
              </ListItemIcon>
        <ListItemText 
          primary={
            <Typography variant="body2" color="textSecondary">
              {jobOffer.jobTitle}
            </Typography>
          }
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
                  <Typography variant="body2" color="textSecondary" >
            {jobOffer.shortJobDescription}
          </Typography>
          <Button
            sx={{ padding: "0px", marginBottom: "16px" }}
            variant="text"
            size="small"
            onClick={handleDialogOpen}
          >
            Read More
          </Button>
        </Box>
      </Collapse>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Job Description</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="textPrimary"
            sx={{ whiteSpace: "pre-line" }}
          >
            {jobOffer.jobDescription}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobOffersListItem;
