import "./css/ProfileStatusBanner.css";

import React from "react";

interface ProfileStatusBannerProps {
  onEditCvClick: () => void;
}

const ProfileStatusBanner: React.FC<ProfileStatusBannerProps> = (props) => {
  return (
    <div className="profile-status-banner">
      <p>
        Your profile editing is not completed. Fill in your CV for easy apply
      </p>
      <button onClick={props.onEditCvClick}>Edit CV</button>
    </div>
  );
};

export default ProfileStatusBanner;
