import { List } from "@mui/material";
import { GraduateCompanyJobOffers } from "../../types/GraduateCompanyJobOffers";
import JobOffersListItem from "./JobOffersListItem";

interface JobOffersListProps {
  companyJobOffers: GraduateCompanyJobOffers;
}

const JobOffersList: React.FC<JobOffersListProps> = (props) => {
  return (
    <List sx={{ padding: 0 }}>
      {props.companyJobOffers.jobOffers.map((jobOffer, index) => (
        <JobOffersListItem index={index} jobOffer={jobOffer} />
      ))}
    </List>
  );
};

export default JobOffersList;
