import { createTheme, responsiveFontSizes } from "@mui/material";
import { red, green } from '@mui/material/colors';

const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#000080',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#DF6951', 
        },
        error: {
            main: red.A400,
        },
        success: {
            main: green[500],
        },

    },
    components: {
        MuiButton:
        {
            styleOverrides: {
                root: {
                    fontFamily: "Plus Jakarta Sans",
                    textTransform: 'none'
                },
                outlined: {
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: '#f5f5f5', // Optional: Change background on hover
                    },
                }
            }
        }
    },
    typography: {

        h1: {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: '60px',
            lineHeight: 1.1,
            letterSpacing: '-0.03em'
        },
        h2: {
            fontWeight: 600,
            fontSize: '48px',
            lineHeight: 1.1,
        },
        h3: {
            fontWeight: 700,
        },
        h4: {
            fontWeight: 700,
        },
        h5: {
            fontWeight: 700,
        },
        h6:
        {
            fontWeight:700
        },
        subtitle2:
        {
            fontWeight: 'bolder',
            color: '#5E6282'

        },
        body2:
        {
            fontWeight: 500,
            color:'#5E6282'
        }
    }
});

export default responsiveFontSizes(mainTheme);
