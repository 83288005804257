import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import CompanyMatchCard from "./CompanyMatchCard";
import { GraduateCompanyResult } from "../../types/GraduateCompanyResult";
import { GraduateCompanyResultAPI } from "../../services/api/graduate-company-result";

const CompanyMatches: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [companyResults, setCompanyResults] = useState<
    GraduateCompanyResult[] | null
  >(null);

  useEffect(() => {
    fetchGraduateCompanyResults();
  }, []);

  const fetchGraduateCompanyResults = async () => {
    try {
      const response = await GraduateCompanyResultAPI.getTopSalary();
      setCompanyResults(response?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const imageList = companyResults?.map((companyResult) => {
    try {
      const companyLogoPath = require(`../../public/CompanyLogos/${companyResult.company.name}.png`);
      return { src: companyLogoPath, name: companyResult.company.name };
    } catch (error) {
      console.error(
        "Error loading image for:",
        companyResult.company.name,
        error
      );
      return { src: null, name: companyResult.company.name };
    }
  });

  return (
    <Grid container spacing={3} sx={{ mt: 4 }} alignItems="center">
      {isLoading ? (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Loading...
          </Typography>
        </Grid>
      ) : (
        companyResults?.map((companyResult, index) => (
          <CompanyMatchCard
            key={index}
            companyResult={companyResult}
            imageSrc={
              imageList
                ? imageList[index]
                : { src: null, name: companyResult.company.name }
            }
          />
        ))
      )}
    </Grid>
  );
};

export default CompanyMatches;
