import axios, { InternalAxiosRequestConfig  } from "axios"
import { UrlUtility } from "../../utils/url-utils";

const api = axios.create({
  baseURL: UrlUtility.getBaseApiUrl(),
  withCredentials: false
});

// Add a request interceptor
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Registering the custom error handler to the "api" axios instance. 
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})

const errorHandler = (error: any) => {
  const statusCode = error.response?.status

  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

export default api;