import { CardActions, Grid, Box } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FeedbackDialog from "../general/FeedbackDialog";
import { useCallback, useState } from "react";
import { GraduateCompanyResult } from "../../types/GraduateCompanyResult";
import { GraduateCompanyResultAPI } from "../../services/api/graduate-company-result";

interface CompanyMatchFeedbackProps {
  companyResult: GraduateCompanyResult;
}

const CompanyMatchFeedback: React.FC<CompanyMatchFeedbackProps> = (props) => {
  const [isInterested, setIsInterested] = useState<boolean>(
    props.companyResult.isInterested
  );
  const [feedback, setFeedback] = useState<string | null>(
    props.companyResult.feedback
  );

  const updateGraduateCompanyResult = (
    graduateCompanyResult: GraduateCompanyResult
  ) => {
    GraduateCompanyResultAPI.update(graduateCompanyResult).catch((e) => {
      console.log(e);
    });
  };

  const handleGivenFeedback = useCallback(
    (companyId: number, interested: boolean, feedback: string) => {
      setIsInterested(interested);
      setFeedback(feedback);

      let company = document.getElementById(companyId + "Company");
      if (company) {
        company.style.background = interested ? "lightgreen" : "lightgray";
      }
      let options = document.getElementById(companyId + "YesNo");
      if (options) options.hidden = true;

      console.log(companyId);
      console.log(interested);
      console.log(feedback);
      props.companyResult.feedback = feedback;
      props.companyResult.isInterested = interested;
      updateGraduateCompanyResult(props.companyResult);
    },
    [props.companyResult]
  );

  return (
    <Grid item>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1 }}></Box>
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "stretch",
            gap: 1,
            width: "100%",
            marginTop: { xs: "10px", md: "0" },
            padding: "0",
          }}
        >
          <FeedbackDialog
            onFeedbackSubmit={(feedback) =>
              handleGivenFeedback(props.companyResult.companyId, true, feedback)
            }
            diaglogTitle={"Glad to hear you like our recommendation!"}
            diaglogBody={`Could you help us by explaining why you are interested in ${props.companyResult.company.name}?`}
            buttonType="yes"
            buttonText="I'm interested"
            buttonProps={{
              variant: "contained",
              fullWidth: true,
              sx: {
                margin: 0,
                backgroundColor: isInterested ? "#008646" : "primary",
                color: "white",
                textTransform: "none",
              },
              startIcon: <ThumbUpIcon />,
            }}
          />
          <FeedbackDialog
            onFeedbackSubmit={(feedback) =>
              handleGivenFeedback(
                props.companyResult.companyId,
                false,
                feedback
              )
            }
            diaglogTitle={"No match, no worries!"}
            diaglogBody={`Could you help improve our recommendation by explaining why you are not interested in ${props.companyResult.company.name}?`}
            buttonType="no"
            buttonText="Not for me"
                      buttonProps={{
                          variant: isInterested ? "contained" : "outlined",
                          fullWidth: true,
                          sx: {
                              marginLeft: -0.5,
                              backgroundColor:
                                  feedback === null
                                      ? "transparent"
                                      : isInterested
                                          ? "transparent"
                                          : "#AB0C0C",
                              color:
                                  feedback === null
                                      ? "#000080"
                                      : isInterested
                                          ? "#000080"
                                          : "white",
                              textTransform: "none",
                              "&:hover": {
                                  backgroundColor:
                                      feedback === null
                                          ? "transparent"
                                          : isInterested
                                              ? "transparent"
                                              : "#8E0A0A", 
                                  color:
                                      feedback === null
                                          ? "#000080"
                                          : isInterested
                                              ? "#000080"
                                              : "white", 
                              },
                          },
                          startIcon: <ThumbDownIcon />,
                      }} 
          />
        </CardActions>
      </Box>
    </Grid>
  );
};

export default CompanyMatchFeedback;
