import "./css/StudentMainComponent.css";

import CompanyMatches from "../company_match/CompanyMatches";
import UserDashboard from "../user_dashboard/UserDashboard";
import UserSettingsComponent from "../user_settings/UserSettingsComponent";
import { PortalSIdebarMenuItems as PortalSidebarMenuItems } from "../../enums/PortalSidebarMenuItems";

interface UserPortalMainProps {
  selectedMenu: string;
  onEditCvClick: () => void;
}

const UserPortalMain: React.FC<UserPortalMainProps> = (props) => {
  const renderContent = () => {
    switch (props.selectedMenu) {
      case PortalSidebarMenuItems.Dashbaord:
        return <UserDashboard onEditCvClick={props.onEditCvClick} />;
      case PortalSidebarMenuItems.Matches:
        return <CompanyMatches />;
      case PortalSidebarMenuItems.Settings:
        return <UserSettingsComponent />;
      default:
        return <UserDashboard onEditCvClick={props.onEditCvClick} />;
    }
  };

  return <div className="main-content">{renderContent()}</div>;
};

export default UserPortalMain;
