import { ThemeProvider } from '@mui/system';
import { Container, Typography, Box, Grid } from '@mui/material';

export default function TermsOfServicePage() {
    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', mt:20, mb:8 }}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="h4" align="center" sx={{ mb: 8 }}>
                            GradGuide Terms of Service
                        </Typography>
                        
                    </Grid>
                </Grid>
                <Container maxWidth="md">
                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                        Effective date: 01/06/2024
                    </Typography>
                    <Typography variant="body1" component="p" sx={{ mt: 1, mb: 5 }}>
                        Welcome to GradGuide (the "Platform"). These Terms of Service (the "Terms") govern your use of our website and services, including our recruitment platform that connects students with Recruiters. By accessing or using our Platform, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, you may not use the Platform.
                    </Typography>


                    <Typography mb={4}>
                        <Typography component="ol"
                            sx={{
                                pl: 2,
                                listStyleType: 'decimal',
                                '& li': {
                                    typography: 'h4', 
                                    display: 'list-item',
                                    '&::marker': {
                                        fontWeight: 'bold',  
                                    },
                                },
                            }}
                        >
                            <li>
                                Mission
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb:1 }}>
                                    Our mission is to connect talent to Employers using AI driven actionable recommendations.
                                    We offer a range of services on our platform to achieve our mission.
                                </Typography>
                            </li>

                            <li>
                                Definitions
                                <Typography variant="body1" component="div" sx={{
                                    '& li': {
                                        typography: 'body1', 
                                        display: 'list-item',
                                        '&::marker': {
                                            fontWeight: 'bold', 
                                        },
                                    },
                                }}>
                                    <ul>
                                        <li>Member: The natural person who accepted these Terms; a Job Seeker or a Recruiter.</li>
                                        <li>Account: The primary means through which a Member accesses the Platform using their login credentials.</li>
                                        <li>Employer: An organization utilizing the Platform for recruitment and/or Employer branding purposes.</li>
                                        <li>Job Seeker: A student or recent graduate seeking employment opportunities and using the Platform to connect with Recruiters.</li>
                                        <li>Recruiter: The individual who uses the Platform on behalf of an Employer to find potential candidates.</li>
                                        <li>Visitor: An individual other than a Member, who accesses the public areas of the Platform without logging in.</li>
                                        <li>Profile Information: Information provided by Members in their profiles, through our survey, and by connecting to Employers, including but not limited to personal, academic, and professional details.</li>
                                        <li>Opportunity: A job, internship, placement, or career event advertised by an Employer on the Platform.</li>
                                        <li>Terms: these Terms of service.</li>
                                        <li>GradGuide Service: The Website, Platform, Content and all content, services and/or features available through the Platform.</li>
                                        <li>Materials: Look and feel, organization, compilation of content, code, data and all other elements of the GradGuide Service.</li>

                                    </ul>
                                </Typography>
                            </li>
                            <li>Eligibility</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                To use the Platform, you must be at least 18 years old or have reached the age of majority in your jurisdiction, and have the legal capacity to enter into these Terms. By using the Platform, you represent and warrant that you meet these eligibility requirements.
                            </Typography>
                            <li>Account Registration</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                To access certain features of the Platform, you must register for an Account. When you register, you agree to:
                                <Typography variant="body1" component="div" sx={{
                                    '& li': {
                                        typography: 'body1',
                                        display: 'list-item',
                                        '&::marker': {
                                            fontWeight: 'bold',
                                        },
                                    },
                                }}>
                                    <ul>
                                        <li>Provide accurate, current, and complete information about yourself.</li>
                                        <li>Maintain and promptly update your information to keep it accurate, current, and complete.</li>
                                        <li>Maintain the security and confidentiality of your login credentials.</li>
                                        <li>Notify us immediately of any unauthorized use of your Account or other security breaches.</li>
                                        <li>Not create an Account under someone else's name unless this person has given permission to do so.</li>
                                        <li>Not transfer the Account to anyone else as an Account is assigned to a designated individual person and is not transferable.</li>
                                    </ul>
                                </Typography>
                            </Typography>
                            <li>Termination of Account</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                A Member may terminate his/her Account by following the designated steps after clicking "Delete my profile" in the "Personal settings" section of the Platform. GradGuide will delete all Member Data following the termination of his/her Account. Gradguide has the right to terminate a Member's Account for no or any reason, at any time, with or without notice.
                            </Typography>
                            <li>Use of The Platform</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                You agree to use the Platform in accordance with these Terms and all applicable laws and regulations. You must not:
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="body1" component="div" sx={{
                                        '& li': {
                                            typography: 'body1',
                                            display: 'list-item',
                                            '&::marker': {
                                                fontWeight: 'bold',
                                            },
                                        },
                                    }}>
                                        <ul>
                                            <li>Use the Platform for any unlawful purpose or to promote illegal activities.</li>
                                            <li>Post or transmit any content that is obscene, offensive, defamatory, or otherwise objectionable.</li>
                                            <li>Use the Platform to impersonate another person or entity, or falsely state or misrepresent your affiliation with a person or entity.</li>
                                            <li>Interfere with or disrupt the operation of the Platform or the servers or networks used to make the Platform available.</li>
                                            <li>Engage in any activity that could damage, disable, overburden, or impair the functioning of the Platform.</li>
                                            <li>Scrape, crawl or spider any data available on the Website or Platform.</li>
                                            <li>Use the platform in a way that results in an infringement of any right of any person or legal entity.</li>

                                        </ul>
                                    </Typography>
                            </Typography>
                            <li>Our Responsibilities</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>Gradguide will make our GradGuide Service available to a Member pursuant to these Terms. By agreeing to these Terms, a Member grants GradGuide a general authorization in the meaning of Article 28 (2) of Regulation (EU) 2016/679 to engage processors for the purposes of providing the GradGuide Service. GradGuide will inform the Member of changes in such processors in accordance with the procedure of modifying these Terms as stipulated in section 13 of these Terms.
                                </Typography>
                                <Typography sx={{ textDecoration: 'underline' }}>
                                List of processors
                                </Typography>
                                <Typography variant="body1" component="div" sx={{
                                    '& li': {
                                        typography: 'body1',
                                        display: 'list-item',
                                        '&::marker': {
                                            fontWeight: 'bold',
                                        },
                                    },
                                }}>
                                    <ul>
                                        <li>N/A.</li>

                                    </ul>
                                </Typography>

                            <li>Privacy</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    Your use of the Platform is also governed by our Privacy Policy, which explains how we collect, use, and disclose your personal information. By using the Platform, you consent to our collection and use of your personal information as outlined in the Privacy Policy.
                            </Typography>
                            <li>Intellectual Property</li>
                            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    All intellectual property rights in the Platform and its content (excluding user-generated content) are owned by or licensed to GradGuide. You may not use, copy, reproduce, modify, distribute, or create derivative works based on the Platform or its content without our prior written consent. Our platform and content includes any trade names and trademarks, any files, software or material, and the website's source code. Any commercial or promotional distribution, publishing or exploitation of the Gradguide Materials is strictly prohibited without our prior consent.
                            </Typography>
                                <li>Disclaimers and Limitation Of Liability</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    The Platform is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the operation or availability of the Platform or the information, content, Materials, or products included on the Platform. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. We do not warrant that the Platform will be uninterrupted or error-free. In no event shall GradGuide, its directors, officers, employees, or agents be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Platform.
                                </Typography>
                                <li>Indemnification</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    You agree to indemnify, defend, and hold harmless GradGuide, its affiliates, and their respective directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses (including reasonable attorney's fees) arising out of or in any way connected with your access to or use of the Platform, your violation of these Terms, or your infringement of any intellectual property or other rights of any third party.
                                </Typography>
                                <li>Termination</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    We may terminate or suspend your access to the Platform at any time, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Platform will immediately cease.
                                </Typography>
                                <li>Changes to These Terms</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the Platform and updating the effective date. You are advised to review these Terms periodically for any changes. Your continued use of the Platform after the posting of changes constitutes your acceptance of such changes.
                                </Typography>
                                <li>Governing Law, Choice of Court, And Severability</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    14.1 Governing law: These Terms shall be governed by and construed in accordance with Dutch law, without regard to its conflict of law provisions.
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    14.2 Choice of court: In the event of a conflict between a Member and GradGuide that cannot be settled or concluded in any other way, the only designated court is the authorized court in Amsterdam.
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    14.3 Severability: If any provision of these Terms is found to be illegal or unenforceable, the remaining provisions will continue to be in full force and effect without being affected.
                                </Typography>
                                <li>Contact Information</li>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
                                    https://www.GradGuide.nl
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ mt: 1, mb: 10 }}>
                                    GradGuide_Privacy@Gmail.com
                                </Typography>
                        </Typography>
                    </Typography>
                </Typography>
            </Container>
        </Box>
    );
}